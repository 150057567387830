import React from 'react'
import { getHttpRequest } from '../../../../api/query/dynamicAPI'
import SelectField from '../../../../components/CustomMultipleSelect/singleOption'
import { useTranslation } from 'react-i18next'
import MeetingActionDecision from './sub-components/MeetingActionDecision'
import TranscriptSummary from './sub-components/TranscriptSummary'
import InPageLoader from '../../../../components/InPageLoader'

const MeetingSummary = () => {
  const [meetingTranscripts, setMeetingTranscripts] = React.useState([])
  const [selectedTranscript, setSelectedTranscript] = React.useState(null)
  const [tabOption, setTabOption] = React.useState(0)
  const [isLoading, setIsLoading] = React.useState(false)
  const [selectedMeetingSummary, setSelectedMeetingSummary] = React.useState([])
  const [selectedMeetingGeneratedItems, setSelectedMeetingGeneratedItems] = React.useState([])
  const [availableTopics, setAvailableTopics] = React.useState([])
  const [topicsOwnedByUser, setTopicsOwnedByUser] = React.useState([])
  const [isSelectedTranscriptFinalized, setIsSelectedTranscriptFinalized] = React.useState(false)
  const [meetingNoteName, setMeetingNoteName] = React.useState('')

  const { t } = useTranslation(['Common', 'MeetingHub'])

  React.useEffect(() => {
    getMeetingTranscripts()
  }, [])

  React.useEffect(() => {
    if (selectedTranscript) {
      setSelectedMeetingGeneratedItems([])
      setSelectedMeetingSummary(null)
      setIsLoading(true)
      getSingleMeetingTranscript(selectedTranscript.meetingInstanceId)
    }
  }, [selectedTranscript])

  const getMeetingTranscripts = async () => {
    setMeetingNoteName('')
    let response = await getHttpRequest('/meeting/get_all_meeting_transcripts')
    setMeetingTranscripts(response.meetingTranscripts)
    setTopicsOwnedByUser(response.topicsOwned)

    if (response.meetingTranscripts.length > 0) {
      setSelectedTranscript(response.meetingTranscripts[0])
    }
  }

  const getSingleMeetingTranscript = async (meetingInstanceId) => {
    let response = await getHttpRequest(
      `/meeting/get_items_generated_in_meeting?meetingInstanceId=${meetingInstanceId}`,
    )
    setSelectedMeetingGeneratedItems(
      response?.meetingTranscriptGeneratedItemWrappers?.filter(
        (topic) => topic.meetingTranscriptGeneratedItems.length > 0 || topic.isAddedCurrentMeeting,
      ) ?? [],
    )

    setSelectedMeetingSummary(response?.shortTermSummaries?.shortTermSummaries ?? [])
    setIsSelectedTranscriptFinalized(response?.isFinalized ?? false)

    let trackedTopics =
      response?.meetingTranscriptGeneratedItemWrappers
        ?.filter((topic) => topic.umtId !== -1)
        ?.map((topic) => ({
          umtDescription: topic.umtDescription,
          umtId: topic.umtId,
          goalId: topic.goalId,
        })) ?? []

    let newAvailableTopics = [...trackedTopics, ...topicsOwnedByUser]

    // remove duplidate topics
    setAvailableTopics(
      newAvailableTopics?.filter(
        (topic, index, arr) => arr.findIndex((val) => val.umtId === topic.umtId) === index,
      ),
    )
    setIsLoading(false)
  }

  const handleMeetingTranscriptChange = (e) => {
    setSelectedTranscript(e)
  }

  const reload = async () => {
    // if (selectedTranscript) {
    //   setIsLoading(true)
    //   await getSingleMeetingTranscript(selectedTranscript.meetingInstanceId)
    // }
    setIsLoading(true)
    await getMeetingTranscripts()
    setIsLoading(false)
  }

  const handleChangeMeetingNoteName = (e) => {
    setMeetingNoteName(e.target.value)
  }

  return (
    <>
      <div style={{ width: '90%', paddingTop: '1rem', paddingLeft: '1rem' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              columnGap: '2rem',
              width: '90%',
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', width: '40%' }}>
              <div style={{ fontWeight: 'bold' }}>{t('MeetingHub:transcript.selectNote')}</div>
              <SelectField
                placeholder={t('formPlaceHolder.select') + '...'}
                name="selectMt"
                value={meetingTranscripts?.filter(
                  (mt) => mt.meetingInstanceId === selectedTranscript?.meetingInstanceId,
                )}
                options={meetingTranscripts}
                onChange={handleMeetingTranscriptChange}
                getOptionLabel={(option) => option.meetingInstanceName}
                getOptionValue={(option) => option.meetingInstanceId}
                width={'100%'}
              />
            </div>
            {selectedTranscript && !selectedTranscript.isFinalized && (
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', width: '40%' }}
              >
                <div style={{ fontWeight: 'bold' }}>{t('MeetingHub:transcript.noteTitle')}</div>
                <input
                  placeholder={t('Common:filter.select') + '...'}
                  value={meetingNoteName}
                  onChange={(e) => {
                    handleChangeMeetingNoteName(e)
                  }}
                  style={{
                    width: '20rem',
                    height: '2.5rem',
                    backgroundColor: 'hsl(0, 0%, 100%)',
                    borderColor: 'hsl(0, 0%, 80%)',
                    borderRadius: '4px',
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    boxSizing: 'border-box',
                  }}
                />
              </div>
            )}
          </div>
          <div className="kpi-report-nav-container" style={{ marginTop: '1rem' }}>
            <div
              className={`nav-option-kpi-report ${tabOption === 0 ? 'active' : ''}`}
              onClick={() => setTabOption(0)}
              style={{ marginLeft: '0' }}
            >
              <span className="nav-option-text-kpi-report">
                {t('MeetingHub:transcript.actionDecision')}
              </span>
            </div>
            <div
              className={`nav-option-kpi-report ${tabOption === 1 ? 'active' : ''}`}
              onClick={() => setTabOption(1)}
            >
              <span className="nav-option-text-kpi-report">
                {t('MeetingHub:transcript.transcriptSummary')}
              </span>
            </div>
          </div>
          {isLoading ? (
            <InPageLoader />
          ) : (
            <>
              {selectedMeetingGeneratedItems && tabOption === 0 && (
                <MeetingActionDecision
                  isFinalized={isSelectedTranscriptFinalized}
                  selectedTranscript={selectedTranscript}
                  meetingGeneratedItems={selectedMeetingGeneratedItems}
                  reload={reload}
                  setSelectedMeetingGeneratedItems={setSelectedMeetingGeneratedItems}
                  availableTopics={availableTopics}
                  setAvailableTopics={setAvailableTopics}
                  meetingNoteName={meetingNoteName}
                />
              )}
              {selectedMeetingSummary && tabOption === 1 && (
                <TranscriptSummary meetingSummary={selectedMeetingSummary} />
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default MeetingSummary

import React from 'react'
import Modal from '../Modal'
import SelectSingleField from '../CustomMultipleSelect/singleOption'
import useUwManageBlocker from '../../api/mutation/useUwManageBlocker'
import useUwManageDecision from '../../api/mutation/useUwManageDecision'
import useUwManageUpdate from '../../api/mutation/useUwManageUpdate'
import useUwManageAdminTopic from '../../api/mutation/useUwManageAdminTopic'
import useGetAllActiveCompanyObj from '../../api/query/useGetAllActiveCompanyObj'
import { useTranslation } from 'react-i18next'
import { getConsolidatedConfigSettings, hasRole } from '../../utils/auth'
import {
  getSameLevelL2Leaders,
  getTeamLeadersForAreaLeaders,
} from '../../api/services/employee.services'
import { getObjectivUserList } from '../../api/services/employee.services'
import './style.css'
import TagInput from '../TagInput'
import SelectField from '../CustomMultipleSelect/singleOption'
import { getHttpRequest, postHttpRequest } from '../../api/query/dynamicAPI'
import Toast from '../../common/toast'
const AddEditMeetingTopicModal = ({
  isModalOpen,
  closeModal,
  loadAgain,
  objIdIn = null,
  textInputIn = '',
  topicId = null,
  topicType = null,
  isProductSettingsEnabled,
  refreshBGUScorecardFn,
  isFromDashBoardAndIsRegularTl = undefined,
  teamLeadersList = undefined,
  fromKanban = false,
  showMineButton = true,
  selectedLeaderToLoadMH = null, //determines who's objectives to show as the topic owner can be different from the objective owner
  //that the topic is related to
  passedTopicOwner, // eid of the topic owner
  createdByName,
  tags, //passing the current list of tags thatg the topic has
  isPriority,
  showToast = false,
  fromReviewObjectiveMap = null,
}) => {
  const { t } = useTranslation(['Common', 'MeetingHub'])
  const [eId, setEid] = React.useState(undefined)
  const [showL3Objectives, setShowL3Objectives] = React.useState(false)
  const { data: objList } = useGetAllActiveCompanyObj({
    isFromDashBoardAndIsRegularTl,
    owner: hasRole('ic') ? undefined : eId,
    selectedLeaderToLoadMH: selectedLeaderToLoadMH,
    showL3ObjsForAlAndJuniorTls: showL3Objectives,
  })
  const [objId, setObjId] = React.useState(objIdIn)
  const [textInput, setTextInput] = React.useState(textInputIn)

  const [invalidateButtons, setInvalidateButtons] = React.useState(false)
  const isDelegateTl = parseInt(localStorage.getItem('dType')) === 5 ? true : false

  const allowL2UsersSeeAllObjsUnderCxo = getConsolidatedConfigSettings(
    'allow_l2_users_see_all_objs_under_cxo',
  )
  const enableAdminTopics = getConsolidatedConfigSettings('enable_admin_topics')
  const allowCxoUsersSeeL3Objs = getConsolidatedConfigSettings('allow_cxo_users_see_l3_objs')

  const [teamLeadersData, setTeamLeadersData] = React.useState([])
  const loggedInName =
    localStorage.getItem('tfin') != 'null'
      ? localStorage.getItem('tfin')
      : localStorage.getItem('tfmnm')
  const [userList, setUserList] = React.useState([])
  const [selectedTopicOwner, setSelectedTopicOwner] = React.useState(
    passedTopicOwner ?? { eId: -1, name: null },
  )
  const [tagsList, setTagsList] = React.useState([])

  const [companyMeetings, setCompanyMeetings] = React.useState([])
  const [topicMeetings, setTopicMeetings] = React.useState([])

  const [updatedTopicType, setUpdatedTopicType] = React.useState(topicType ?? 1)

  const [charCount, setCharCount] = React.useState(textInputIn?.length)
  let topicsList = [
    {
      topicType: 1,
      topicName: t('MeetingHub:meetingTopics.blocker'),
    },
    {
      topicType: 2,
      topicName: t('MeetingHub:meetingTopics.guidance'),
    },
    {
      topicType: 3,
      topicName: t('MeetingHub:meetingTopics.update'),
    },
  ]

  if (enableAdminTopics) {
    topicsList.push({
      topicType: 4,
      topicName: t('MeetingHub:meetingTopics.adminTopic'),
    })
  }

  React.useEffect(() => {
    getUserList()
    teamLeadersForAreaLeaders()

    getMeetings()
    if (topicId != null) {
      getMeetingTopicMeetings()
    }

    if (allowL2UsersSeeAllObjsUnderCxo || allowCxoUsersSeeL3Objs) {
      if (allowL2UsersSeeAllObjsUnderCxo) {
        showMineButton = true
      }
      setShowL3Objectives(true)
    }
  }, [])

  React.useEffect(() => {
    //For Brian and his L2's, we initially show all the objectives so we don't need to set the eId
    if (
      teamLeadersData &&
      teamLeadersData.employees &&
      !allowCxoUsersSeeL3Objs &&
      !allowL2UsersSeeAllObjsUnderCxo &&
      fromReviewObjectiveMap === null &&
      selectedLeaderToLoadMH === null
    ) {
      let personToSet = teamLeadersData.employees.find((person) => person.name === loggedInName)

      if (personToSet) {
        setEid(personToSet.eId)
      } else {
        setEid(teamLeadersData.employees[0].eId)
      }
    } else if (fromReviewObjectiveMap !== null || selectedLeaderToLoadMH) {
      setEid(selectedLeaderToLoadMH)
    }
  }, [teamLeadersData])

  React.useEffect(() => {
    if (!passedTopicOwner) {
      setSelectedTopicOwner(
        userList?.filter((person) => person.email == localStorage.getItem('tfmu'))[0],
      )
    }
  }, [userList])

  const getMeetings = async () => {
    try {
      let result = await getHttpRequest('/get_company_meetings')
      setCompanyMeetings(result.data)
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }

  const getMeetingTopicMeetings = async () => {
    try {
      let result = await getHttpRequest(`/get_meeting_topic_meetings/${topicId}`)
      setTopicMeetings(result.meetings)
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'Something went wrong',
      })
    }
  }

  async function teamLeadersForAreaLeaders() {
    let result = []
    if (allowL2UsersSeeAllObjsUnderCxo) {
      result = await getSameLevelL2Leaders()
    } else {
      if (selectedLeaderToLoadMH) {
        result = await getTeamLeadersForAreaLeaders(selectedLeaderToLoadMH, true)
      } else {
        if (allowCxoUsersSeeL3Objs) {
          result = await getTeamLeadersForAreaLeaders(null, false)
        } else {
          result = await getTeamLeadersForAreaLeaders(null, true)
        }
      }

      //result = selectedLeaderToLoadMH ? await getTeamLeadersForAreaLeaders(selectedLeaderToLoadMH, true) : await getTeamLeadersForAreaLeaders();
    }
    if (result?.employees) {
      result.employees = result.employees.filter((person) => person.isDeleted !== true)
      setTeamLeadersData(result)
    }
  }

  async function getUserList() {
    const result = await getObjectivUserList(objIdIn)
    if (result?.employees) setUserList(result.employees)
  }

  const textInputChangeHandler = (event) => {
    validate()
    if (event.target.value.length > 300) {
      return
    }
    setCharCount(event.target.value.length)
    setTextInput(event.target.value)
  }

  const inputChangedHandler = (e) => {
    setObjId(parseInt(e?.id))
  }

  const topicChangedHandler = (e) => {
    setUpdatedTopicType(e.target.value)
  }

  const mutationBlocker = useUwManageBlocker()
  const mutationDecision = useUwManageDecision()
  const mutationUpdate = useUwManageUpdate()
  const mutationAdminTopic = useUwManageAdminTopic()

  const [errorMessageDropDown, setErrorMessageDropDown] = React.useState(null)
  const [errorMessageTextArea, setErrorMessageTextArea] = React.useState(null)

  const manageBlocker = async (action, obj) => {
    let data = {}
    data.action = action
    data.blocker = obj
    if (topicMeetings) {
      data.meetings = topicMeetings.map((item) => item.meetingId)
    }

    await mutationBlocker.mutateAsync(data, {
      onSuccess: () => {
        setTextInput('')
        setInvalidateButtons(false)
        if (loadAgain !== undefined) {
          loadAgain()
        }
        closeModal()
        if (refreshBGUScorecardFn !== undefined) {
          refreshBGUScorecardFn()
        }
        if (showToast) {
          Toast.fire({
            icon: 'success',
            title: t('Common:modalMessages.meetingTopicSavedSuccessfully'),
          })
        }
      },
      onError: () => {
        closeModal()
        if (showToast) {
          Toast.fire({
            icon: 'error',
          })
        }
      },
    })
  }

  const manageDecision = async (action, obj) => {
    let data = {}
    data.action = action
    data.decision = obj
    if (topicMeetings) {
      data.meetings = topicMeetings.map((item) => item.meetingId)
    }

    await mutationDecision.mutateAsync(data, {
      onSuccess: () => {
        setTextInput('')
        setInvalidateButtons(false)
        if (loadAgain !== undefined) {
          loadAgain()
        }
        closeModal()
        if (refreshBGUScorecardFn !== undefined) {
          refreshBGUScorecardFn()
        }
        if (showToast) {
          Toast.fire({
            icon: 'success',
            title: t('Common:modalMessages.meetingTopicSavedSuccessfully'),
          })
        }
      },
      onError: () => {
        closeModal()
        if (showToast) {
          Toast.fire({
            icon: 'error',
          })
        }
      },
    })
  }

  const manageUpdate = async (action, obj) => {
    let data = {}
    data.action = action
    data.update = obj
    if (topicMeetings) {
      data.meetings = topicMeetings.map((item) => item.meetingId)
    }

    await mutationUpdate.mutateAsync(data, {
      onSuccess: () => {
        setTextInput('')
        setInvalidateButtons(false)
        if (loadAgain !== undefined) {
          loadAgain()
        }
        closeModal()
        if (refreshBGUScorecardFn !== undefined) {
          refreshBGUScorecardFn()
        }
        if (showToast) {
          Toast.fire({
            icon: 'success',
            title: t('Common:modalMessages.meetingTopicSavedSuccessfully'),
          })
        }
      },
      onError: () => {
        closeModal()
        if (showToast) {
          Toast.fire({
            icon: 'error',
          })
        }
      },
    })
  }

  const manageAdminTopic = async (action, obj) => {
    let data = {}
    data.action = action

    data.adminTopic = obj
    if (topicMeetings) {
      data.meetings = topicMeetings.map((item) => item.meetingId)
    }

    await mutationAdminTopic.mutateAsync(data, {
      onSuccess: () => {
        setTextInput('')
        setInvalidateButtons(false)
        if (loadAgain !== undefined) {
          loadAgain()
        }
        closeModal()
        if (refreshBGUScorecardFn !== undefined) {
          refreshBGUScorecardFn()
        }
        if (showToast) {
          Toast.fire({
            icon: 'success',
            title: t('Common:modalMessages.meetingTopicSavedSuccessfully'),
          })
        }
      },
      onError: () => {
        closeModal()
        if (showToast) {
          Toast.fire({
            icon: 'error',
          })
        }
      },
    })
  }

  const validate = () => {
    objId === null
      ? setErrorMessageDropDown(t('formError.required'))
      : setErrorMessageDropDown(null)
    !/\S/.test(textInput)
      ? setErrorMessageTextArea(t('formError.required'))
      : setErrorMessageTextArea(null)
  }

  const handleOwnerChange = (e) => {
    setEid(e?.eId)
  }

  const updateSelectedTopicOwner = (e) => {
    setSelectedTopicOwner(e)
  }

  const handleMineClick = () => {
    let empName =
      localStorage.getItem('tfin') != 'null'
        ? localStorage.getItem('tfin')
        : localStorage.getItem('tfmnm')
    //Brian should see all the objectives when no one is selected
    if (!allowCxoUsersSeeL3Objs) {
      setEid(teamLeadersData?.employees?.find((person) => person.name == empName)?.eId)
    } else {
      setEid(undefined)
    }
  }

  const updateTopicMeetings = (selectedOption) => {
    setTopicMeetings(selectedOption)
  }

  return (
    <>
      <Modal
        title={t('addEditMeetingTopicNewModal.title')}
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        titleStyle={{ color: 'rgb(91,132,203)', textAlign: 'left' }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '1rem' }}>
          {(hasRole('area') ||
            hasRole('cxo') ||
            hasRole('ceo') ||
            hasRole('cos') ||
            isDelegateTl) &&
            !isFromDashBoardAndIsRegularTl &&
            !fromKanban &&
            !hasRole('ic') && (
              <>
                {(allowL2UsersSeeAllObjsUnderCxo || allowCxoUsersSeeL3Objs) && (
                  <div style={{ display: 'flex', flexDirection: 'column', columnGap: '1rem' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem' }}>
                      <div
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          width: 'fitContent',
                          height: 'fitContent',
                          borderRadius: '4px',
                          color: 'white',
                          padding: '0.3rem 1rem 0.3rem 1rem',
                          backgroundColor: showL3Objectives ? '#4472c4' : '#a6a6a6',
                        }}
                        onClick={() => {
                          setShowL3Objectives(true)
                        }}
                      >
                        {t('Common:commonButtons.L3')}
                      </div>

                      <div
                        style={{
                          cursor: 'pointer',
                          display: 'flex',
                          width: 'fitContent',
                          height: 'fitContent',
                          borderRadius: '4px',
                          color: 'white',
                          padding: '0.3rem 1rem 0.3rem 1rem',
                          backgroundColor: !showL3Objectives ? '#4472c4' : '#a6a6a6',
                        }}
                        onClick={() => {
                          setShowL3Objectives(false)
                        }}
                      >
                        {t('Common:commonButtons.L2')}
                      </div>
                    </div>
                  </div>
                )}
                <div
                  style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <div style={{ textAlign: 'left' }}>
                    {t('addEditMeetingTopicNewModal.showObj')}
                  </div>
                  <div style={{ textAlign: 'right', width: '35%' }}>
                    <SelectSingleField
                      placeholder={t('formPlaceHolder.owner')}
                      name="selectOwner"
                      // value={ eId !== null ?
                      //   teamLeadersData?.employees?.filter(
                      //   (person) => parseInt(person.eId) == parseInt(eId).eId)
                      //   : ''}
                      value={teamLeadersData?.employees?.filter(
                        (person) => parseInt(person.eId) == parseInt(eId),
                      )}
                      options={teamLeadersData?.employees}
                      onChange={handleOwnerChange}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.eId}
                      width={'100%'}
                    />
                  </div>
                </div>
              </>
            )}
          <div style={{ textAlign: 'left', fontWeight: 'bold' }}>
            <span>{t('addEditMeetingTopicNewModal.objText')}</span>
            {(objIdIn === null || fromKanban) && <span style={{ color: 'red' }}> *</span>}
          </div>
          {(!fromKanban || fromReviewObjectiveMap != null) && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <SelectSingleField
                placeholder={t('formPlaceHolder.selectObjective')}
                name="selectProject"
                value={objList?.objectives?.filter((item) => parseInt(item.id) == parseInt(objId))}
                options={objList?.objectives}
                onChange={inputChangedHandler}
                getOptionLabel={(option) =>
                  isProductSettingsEnabled && !option?.isGeneralObjective
                    ? `${option?.initiativeId.toString()?.padStart(5, '0')} - ${
                        !option?.isOriginal ? 'SHARED - ' : ''
                      }${option.statement}`
                    : `${!option?.isOriginal ? 'SHARED - ' : ''}${option.statement}`
                }
                getOptionValue={(option) => option.id}
                width={'100%'}
              />
              <div style={{ textAlign: 'left' }} className="validation-error">
                {errorMessageDropDown}
              </div>
            </div>
          )}
          {objIdIn && !fromKanban && (
            <div style={{ whiteSpace: 'nowrap' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div
                  style={{
                    textAlign: 'left',
                    display: 'flex',
                    alignItems: 'center',
                    width: '50%',
                    fontWeight: 'bold',
                  }}
                >
                  {t('addEditMeetingTopicNewModal.createdBy')}
                </div>
                <div
                  className="create-box"
                  style={{
                    textAlign: 'right',
                    height: '30px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  {createdByName}
                </div>
              </div>
            </div>
          )}

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ textAlign: 'left', fontWeight: 'bold' }}>
              <span>{t('addEditMeetingTopicNewModal.ownedBy')}</span>
            </div>
            <div style={{ textAlign: 'right', width: '35%' }}>
              <SelectSingleField
                placeholder={t('formPlaceHolder.selectOwner')}
                name="selectOwner"
                value={selectedTopicOwner}
                options={userList}
                onChange={updateSelectedTopicOwner}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.eId}
                width={'100%'}
              />
            </div>
          </div>

          <>
            <div style={{ textAlign: 'left', fontWeight: 'bold' }}>
              <span>{t('addEditMeetingTopicModal.topicType')}</span>
              {(objIdIn === null || fromKanban) && <span style={{ color: 'red' }}> *</span>}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div>
                <label className="radio-input">
                  <div class="dot-blocker" /> Blocker
                  <input
                    style={{ marginLeft: '25px' }}
                    type="radio"
                    name="type"
                    value="1"
                    onChange={topicChangedHandler}
                    defaultChecked={!topicType || topicType === 1}
                  />
                </label>
                <label className="radio-input">
                  <div class="dot-guidance" /> Guidance
                  <input
                    style={{ marginLeft: '10px' }}
                    type="radio"
                    name="type"
                    value="2"
                    onChange={topicChangedHandler}
                    defaultChecked={topicType === 2}
                  />
                </label>
                <label className="radio-input">
                  <div class="dot-update" /> Update
                  <input
                    style={{ marginLeft: '26px' }}
                    type="radio"
                    name="type"
                    value="3"
                    onChange={topicChangedHandler}
                    defaultChecked={topicType === 3}
                  />
                </label>
                {enableAdminTopics && (
                  <label className="radio-input">
                    <div class="dot-admin" /> Admin
                    <input
                      style={{ marginLeft: '32px' }}
                      type="radio"
                      name="type"
                      value="4"
                      onChange={topicChangedHandler}
                      defaultChecked={topicType === 4}
                    />
                  </label>
                )}
              </div>
            </div>
          </>

          <div>
            <textarea
              rows="6"
              id="multiSubActivityText"
              name="multiSubActivityText"
              value={textInput}
              onChange={(e) => {
                textInputChangeHandler(e)
              }}
              style={{
                resize: 'none',
                marginTop: '1rem',
                border: 'none',
                borderRadius: '10px',
                padding: '0.5rem',
                backgroundColor: 'rgb(242, 242, 242)',
                fontSize: '14px',
                width: '26rem',
              }}
            />
            <div
              style={{
                textAlign: 'right',
                marginTop: '0.5rem',
                fontSize: '12px',
                color: charCount == 300 ? 'red' : 'gray',
              }}
            >
              {charCount}/300
            </div>
          </div>
          <div style={{ textAlign: 'left' }} className="validation-error">
            {errorMessageTextArea}
          </div>
          {fromReviewObjectiveMap === null && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: '1rem' }}>
                <span>{t('addEditMeetingTopicModal.tags')}:</span>
              </div>
              <div style={{ textAlign: 'left' }}>
                <TagInput existingTags={tags} setTagsExternally={setTagsList} meetingTopic={true} />
              </div>
            </div>
          )}
          {fromReviewObjectiveMap === null && (
            <div
              style={{
                textAlign: 'left',
                display: 'flex',
                flexDirection: 'column',
                marginBottom: '1rem',
                // flexDirection: 'column',
                // rowGap: '0.5rem',
                // width: '18rem',
              }}
            >
              <div style={{ textAlign: 'left', fontWeight: 'bold', marginBottom: '1rem' }}>
                <label>{t('addEditMeetingTopicModal.addToMeeting')}: </label>
              </div>

              <SelectField
                name="selectMeeting"
                value={topicMeetings}
                options={companyMeetings}
                onChange={updateTopicMeetings}
                getOptionLabel={(option) => option.meetingName}
                getOptionValue={(option) => option.meetingId}
                width={'19rem'}
                isMulti={true}
                menuPosition="fixed"
                isClearable={true}
              />
            </div>
          )}
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}
        ></div>
        <div style={{ justifyContent: 'center', display: 'flex' }}>
          <button
            type="submit"
            className="sleek-button sleek-blue"
            style={{ cursor: 'pointer', height: '2.2rem', backgroundColor: 'white' }}
            onClick={() => {
              validate()
              debugger
              if (invalidateButtons || !/\S/.test(textInput) || objId === null) {
                return
              } else {
                if (updatedTopicType == 1) {
                  if (topicId === null) {
                    setInvalidateButtons(true)
                    manageBlocker('add', {
                      description: textInput,
                      objId: objId,
                      topicType: 'blocker',
                      topicOwnerId: selectedTopicOwner.eId,
                      tags: tagsList,
                    })
                  } else if (topicId !== null) {
                    setInvalidateButtons(true)
                    manageBlocker('edit', {
                      description: textInput,
                      objId: objId,
                      topicType: 'blocker',
                      isPriority: isPriority,
                      mtId: topicId,
                      topicOwnerId: selectedTopicOwner.eId,
                      updatedTopic: updatedTopicType,
                      asigneeChange:
                        parseInt(passedTopicOwner?.eId) != parseInt(selectedTopicOwner?.eId),
                      tags: tagsList,
                    })
                  }
                }
                if (updatedTopicType == 2) {
                  if (topicId === null) {
                    setInvalidateButtons(true)
                    manageDecision('add', {
                      description: textInput,
                      objId: objId,
                      topicType: 'decision',
                      topicOwnerId: selectedTopicOwner.eId,
                      tags: tagsList,
                    })
                  } else if (topicId !== null) {
                    setInvalidateButtons(true)
                    manageDecision('edit', {
                      description: textInput,
                      objId: objId,
                      topicType: 'decision',
                      isPriority: isPriority,
                      mtId: topicId,
                      topicOwnerId: selectedTopicOwner.eId,
                      updatedTopic: updatedTopicType,
                      asigneeChange:
                        parseInt(passedTopicOwner?.eId) != parseInt(selectedTopicOwner?.eId),
                      tags: tagsList,
                    })
                  }
                }
                if (updatedTopicType == 3) {
                  if (topicId === null) {
                    setInvalidateButtons(true)
                    manageUpdate('add', {
                      description: textInput,
                      objId: objId,
                      topicType: 'update',
                      topicOwnerId: selectedTopicOwner.eId,
                      tags: tagsList,
                    })
                  } else if (topicId !== null) {
                    console.log('here3')
                    setInvalidateButtons(true)
                    manageUpdate('edit', {
                      description: textInput,
                      objId: objId,
                      topicType: 'update',
                      isPriority: isPriority,
                      mtId: topicId,
                      topicOwnerId: selectedTopicOwner.eId,
                      updatedTopic: updatedTopicType,
                      asigneeChange:
                        parseInt(passedTopicOwner?.eId) != parseInt(selectedTopicOwner?.eId),
                      tags: tagsList,
                    })
                  }
                }
                if (updatedTopicType == 4) {
                  if (topicId === null) {
                    setInvalidateButtons(true)
                    manageAdminTopic('add', {
                      description: textInput,
                      objId: objId,
                      topicType: 'adminTopic',
                      topicOwnerId: selectedTopicOwner.eId,
                      tags: tagsList,
                    })
                  } else if (topicId !== null) {
                    setInvalidateButtons(true)
                    manageAdminTopic('edit', {
                      description: textInput,
                      objId: objId,
                      topicType: 'adminTopic',
                      isPriority: isPriority,
                      mtId: topicId,
                      topicOwnerId: selectedTopicOwner.eId,
                      updatedTopic: updatedTopicType,
                      asigneeChange:
                        parseInt(passedTopicOwner?.eId) != parseInt(selectedTopicOwner?.eId),
                      tags: tagsList,
                    })
                  }
                }
              }
            }}
          >
            <span>{t('commonButtons.save')}</span>
          </button>
        </div>
      </Modal>
    </>
  )
}

export default AddEditMeetingTopicModal

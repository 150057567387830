import React from 'react'
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu'
import '@szhsin/react-menu/dist/core.css'
import './styles.scss'
import { useTranslation } from 'react-i18next'
import { getCompanyConfigSettings, hasRole, getConsolidatedConfigSettings } from '../../utils/auth'
import Swal from 'sweetalert2'
import Toast from '../../common/toast'
import { postHttpRequest } from '../../api/query/dynamicAPI'

function SooDropdownMenu(props) {
  const productSettings = getCompanyConfigSettings('productSettings')
  const { t } = useTranslation(['MeetingHub'])
  // const enableAiMemo = getConsolidatedConfigSettings('enable_ai_memo') === true

  return (
    <div>
      <Menu
        menuClassName="soo-menu"
        menuButton={
          <MenuButton
            style={{
              backgroundColor: !props.aiGenPendingApproval ? 'white' : '#f2f2f2',
              border: 'none',
              padding: '0',
            }}
          >
            <span style={{ alignSelf: 'center' }} class="material-symbols-outlined">
              more_vert
            </span>
          </MenuButton>
        }
      >
        {props.fromModal ? (
          <>
            {/* in BGU Modal */}
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                props.onClickEdit()
              }}
            >
              {t('summaryOfObjectives.edit')}
            </MenuItem>
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                props.onClickComment()
              }}
            >
              {t('summaryOfObjectives.comment')}
            </MenuItem>
            {props.fromAMA && (
              <MenuItem
                style={{ textAlign: 'left' }}
                onClick={() => {
                  props.onClickSeeTopic()
                }}
              >
                {t('summaryOfObjectives.seeTopic')}
              </MenuItem>
            )}
          </>
        ) : props.isFromMeetingHub ? (
          <>
            {/* in Meeting Hub */}
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                props.onClickEdit()
              }}
            >
              {t('summaryOfObjectives.edit')}
            </MenuItem>
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                props.onClickDelete()
              }}
            >
              {t('summaryOfObjectives.delete')}
            </MenuItem>
            {props.isAction && props.aiMemoCount === 0 && (
              <MenuItem
                style={{ textAlign: 'left' }}
                onClick={() => {
                  props.onClickAiMemo()
                }}
              >
                {t('summaryOfObjectives.aiMemo')}
              </MenuItem>
            )}
            {props.isAction && props.aiMemoCount > 0 && (
              <MenuItem
                style={{ textAlign: 'left' }}
                onClick={() => {
                  props.onClickDraftMemo()
                }}
              >
                {t('summaryOfObjectives.aiMemoDraft')}
              </MenuItem>
            )}
            {props.isAction && (
              <MenuItem
                style={{ textAlign: 'left' }}
                onClick={() => {
                  props.onMoveTopic()
                }}
              >
                {t('summaryOfObjectives.move')}
              </MenuItem>
            )}

            {props.fileId !== '' && props.fileId !== undefined && (
              <MenuItem style={{ textAlign: 'left' }} onClick={props.onClickDownloadFile}>
                {t('summaryOfObjectives.downloadFile')}
              </MenuItem>
            )}

            {props.showMeetingDeck && (props.fileId === '' || props.fileId === undefined) && (
              <MenuItem style={{ textAlign: 'left' }} onClick={props.onClickUploadFile}>
                {t('summaryOfObjectives.uploadFile')}

                <input
                  key={props.milestoneId.toString()}
                  type="file"
                  id="newFile"
                  name="filenameNew"
                  ref={props.actionUploadRef}
                  accept=".doc,.docx,.xls,.xlsx,.ppt,.pptx,.pdf,.png,.jpg,.jpeg,.svg,.txt,.csv"
                  onChange={(e) => props.onChangeFile(e)}
                  style={{ display: 'none' }}
                ></input>
              </MenuItem>
            )}
          </>
        ) : (
          <>
            {/* in SOO */}
            {!props.obj.isGeneralObjective &&
              props.canAddEditObjs &&
              props.obj?.level === 3 &&
              !hasRole('ic') && (
                <MenuItem
                  onClick={() => {
                    props.onClickEdit()
                  }}
                >
                  {t('summaryOfObjectives.edit')}
                </MenuItem>
              )}
            {productSettings === true && (
              <MenuItem
                onClick={() => {
                  props.onClickComment()
                }}
              >
                {t('summaryOfObjectives.comment')}
              </MenuItem>
            )}

            <MenuItem
              onClick={() => {
                props.onClickAssignees()
              }}
            >
              {t('summaryOfObjectives.assignees')}
            </MenuItem>

            {!(productSettings && hasRole('ic')) && (
              <>
                <MenuItem
                  onClick={() => {
                    props.onClickHistory()
                  }}
                >
                  {t('summaryOfObjectives.history')}
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    props.onClickRequestUpdate()
                  }}
                >
                  {t('summaryOfObjectives.requestUpdate')}
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    props.onClickChangelog()
                  }}
                >
                  {t('summaryOfObjectives.viewChangeLog')}
                </MenuItem>
              </>
            )}
          </>
        )}
      </Menu>
    </div>
  )
}

export default SooDropdownMenu

export const AdminDropdownMenu = ({
  hasEdit = true,
  hasMove = false,
  hasDeactivate = false,
  hasDelete = false,
  isCeo = false,
  leaderData = null,
  onClickEdit = () => {},
  onClickMove = () => {},
  onClickChangeEmployeeState = () => {},
  onClickDelete = () => {},
  reload = () => {},
}) => {
  const { t } = useTranslation(['Dashboard', 'Common'])

  const onClickGeneralObj = async () => {
    const result = await Swal.fire({
      title: t('Common:modalMessages.areYouSureCreateGeneralObjective'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    })
    if (result.isConfirmed) {
      try {
        let params = {
          leaderId: leaderData?.eId,
        }
        let res = await postHttpRequest(`/create_general_objective`, params)
        if (res.success) {
          reload()
          Toast.fire({
            icon: 'success',
            title: t('Common:modalMessages.deletedSuccessfully'),
          })
        } else {
          Toast.fire({
            icon: 'error',
            title: t('Common:modalMessages.youCannotDeleteThis'),
          })
        }
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      }
    }
  }

  return (
    <div>
      <Menu
        menuClassName="soo-menu"
        menuButton={
          <MenuButton style={{ backgroundColor: 'white', border: 'none', padding: '0' }}>
            <span style={{ alignSelf: 'center' }} class="material-symbols-outlined">
              more_vert
            </span>
          </MenuButton>
        }
      >
        <>
          {hasEdit && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                onClickEdit()
              }}
            >
              {t('adminDashboard.edit')}
            </MenuItem>
          )}
          {hasMove && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                onClickMove()
              }}
            >
              {t('adminDashboard.move')}
            </MenuItem>
          )}
          {hasDeactivate && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                onClickChangeEmployeeState()
              }}
            >
              {t('adminDashboard.deactivate')}
            </MenuItem>
          )}
          {!hasDeactivate && !isCeo && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                onClickChangeEmployeeState()
              }}
            >
              {t('adminDashboard.activate')}
            </MenuItem>
          )}
          {hasDelete && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                onClickDelete()
              }}
            >
              {t('adminDashboard.delete')}
            </MenuItem>
          )}
          {!leaderData?.hasGeneralObjective && (
            <MenuItem
              style={{ textAlign: 'left' }}
              onClick={() => {
                onClickGeneralObj()
              }}
            >
              {t('adminDashboard.createGeneralObj')}
            </MenuItem>
          )}
        </>
      </Menu>
    </div>
  )
}

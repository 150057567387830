import React from 'react'
import styled from 'styled-components'
import './style.css'
import { removeMentionMarkupFromText } from '../../../../../utils/parseMarkup'
import { useTranslation } from 'react-i18next'
import UpdateMeetingTopicModal from './UpdateMeetingTopicModal'
import Toast from '../../../../../common/toast'
import { postHttpRequest } from '../../../../../api/query/dynamicAPI'
import Swal from 'sweetalert2'
import TextArea from 'rc-textarea'
import AssigneeActivity from '../../MeetingTopics/common/assigneeActivity'

const ObjectiveStatement = styled.div`
  font-size: 16px;
  width: -moz-fit-content;
  width: fit-content;
  word-break: break-word;
`

const MeetingActionDecision = ({
  meetingGeneratedItems = [],
  selectedTranscript,
  reload = () => {},
  setSelectedMeetingGeneratedItems = () => {},
  availableTopics = [],
  isFinalized = false,
  setAvailableTopics = () => {},
  meetingNoteName = '',
}) => {
  console.log('meetingGeneratedItems: ', meetingGeneratedItems)
  const { t } = useTranslation(['Common'])
  const [isModalOpen, setIsModalOpen] = React.useState(false)
  const [selectedItem, setSelectedItem] = React.useState(null)
  const [selectedTopic, setSelectedTopic] = React.useState(null)
  const [isAssignmentModalOpen, setIsAssignmentModalOpen] = React.useState(false)
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(null)
  const [selectedTopicIndex, setSelectedTopicIndex] = React.useState(null)

  const handleItemEdit = (item, topic) => {
    setSelectedItem(item)
    setSelectedTopic(topic)
  }

  const transferItemToTopic = (selectedItem, newSelectedTopic, currentTopic) => {
    // console.log('currentTopic: ', currentTopic)
    // console.log('newSelectedTopic: ', newSelectedTopic)
    // console.log('selectedItem: ', selectedItem)

    const tempmMeetingGeneratedItems = meetingGeneratedItems.map((topic) => ({
      ...topic,
      meetingTranscriptGeneratedItems: topic.meetingTranscriptGeneratedItems.map((subItem) => ({
        ...subItem,
      })),
    }))

    let destTopic = tempmMeetingGeneratedItems.find(
      (topic) => topic.umtId === newSelectedTopic.umtId,
    )
    const sourceTopic = tempmMeetingGeneratedItems.find(
      (topic) => topic.umtId === currentTopic.umtId,
    )

    if (!sourceTopic) return

    if (!destTopic) {
      const newTopic = {
        umtId: newSelectedTopic.umtId,
        umtDescription: newSelectedTopic.umtDescription,
        meetingTranscriptGeneratedItems: [],
        goalId: newSelectedTopic.goalId,
      }
      tempmMeetingGeneratedItems.push(newTopic)
      destTopic = newTopic
    }

    sourceTopic.meetingTranscriptGeneratedItems =
      sourceTopic.meetingTranscriptGeneratedItems.filter(
        (item) => item.description.trim() !== selectedItem.description.trim(),
      )

    // do we need this???
    const copy = { ...selectedItem }
    destTopic.meetingTranscriptGeneratedItems.push(copy)

    setSelectedMeetingGeneratedItems(
      tempmMeetingGeneratedItems.filter(
        (topic) => topic.meetingTranscriptGeneratedItems.length > 0 || topic.isAddedCurrentMeeting,
      ),
    )
  }

  const handleItemUpdate = (
    itemIndex,
    topicIndex,
    isAccepted = false,
    value,
    isTextUpdate = false,
    isAssigneeUpdate = false,
  ) => {
    const tempmMeetingGeneratedItems = meetingGeneratedItems.map((topic) => ({
      ...topic,
      meetingTranscriptGeneratedItems: topic.meetingTranscriptGeneratedItems.map((subItem) => ({
        ...subItem,
      })),
    }))

    const topic = tempmMeetingGeneratedItems[topicIndex]
    const item = topic.meetingTranscriptGeneratedItems[itemIndex]

    if (isAssigneeUpdate) {
      item.assigneeList = value
    } else if (!isTextUpdate) {
      item.isAccepted = isAccepted
    } else {
      item.description = value
    }

    setSelectedMeetingGeneratedItems(tempmMeetingGeneratedItems)
  }

  const handleFinalizeMeeting = async () => {
    const tempmMeetingGeneratedItems = meetingGeneratedItems
      .filter((topic) => topic.umtId !== -1)
      .map((topic) => ({
        ...topic,
        meetingTranscriptGeneratedItems: topic.meetingTranscriptGeneratedItems.map((subItem) => ({
          ...subItem,
        })),
      }))

    const actionRequiredUpdates = []
    const commentRequiredUpdates = []

    const actionNew = []
    const commentNew = []

    const topicsShouldBeDeleted = []

    tempmMeetingGeneratedItems.forEach((topic) => {
      if (
        (topic.isAddedCurrentMeeting && topic.meetingTranscriptGeneratedItems.length === 0) ||
        topic.isDeleted
      ) {
        topicsShouldBeDeleted.push(topic)
      }
      // deleted topic should not have any items
      topic.meetingTranscriptGeneratedItems.forEach((item) => {
        item.umtId = topic.umtId
        item.goalId = topic.goalId
        if (item.id === 0) {
          if (item.isComment) {
            commentNew.push(item)
          } else {
            actionNew.push(item)
          }
        } else {
          if (item.isComment) {
            commentRequiredUpdates.push(item)
          } else {
            actionRequiredUpdates.push(item)
          }
        }
      })
    })

    // console.log('actionRequiredUpdates: ', actionRequiredUpdates)
    // console.log('commentRequiredUpdates: ', commentRequiredUpdates)
    // console.log('actionNew: ', actionNew)
    // console.log('commentNew: ', commentNew)
    // console.log('topicsShouldBeDeleted: ', topicsShouldBeDeleted)

    let result = await Swal.fire({
      title: t('Common:modalMessages.areYouSureFinalizeMeeting'),
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    })

    if (result.isConfirmed) {
      try {
        await postHttpRequest('/meeting/finalize_meeting', {
          meetingInstanceId: selectedTranscript.meetingInstanceId,
          actionRequiredUpdates,
          commentRequiredUpdates,
          actionNew,
          commentNew,
          topicsShouldBeDeleted,
          meetingNoteName,
        })
        reload()
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      }
    }
  }

  const deleteTopic = async (value) => {
    const tempmMeetingGeneratedItems = meetingGeneratedItems.map((topic) => ({
      ...topic,
      meetingTranscriptGeneratedItems: topic.meetingTranscriptGeneratedItems.map((subItem) => ({
        ...subItem,
      })),
    }))

    let deletedTopicState = tempmMeetingGeneratedItems.find((topic) => topic.umtId === value.umtId)
    if (!deletedTopicState) return

    const result = await Swal.fire({
      title: t('Common:modalMessages.areYouSureDeleteThis'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    })
    if (result.isConfirmed) {
      deletedTopicState.isDeleted = true
      deletedTopicState.meetingTranscriptGeneratedItems.forEach((item) => (item.isAccepted = false))
      setSelectedMeetingGeneratedItems(tempmMeetingGeneratedItems)
      setAvailableTopics((prev) => prev.filter((topic) => topic.umtId !== value.umtId))
    }
  }

  const addBackTopic = async (value) => {
    const tempmMeetingGeneratedItems = meetingGeneratedItems.map((topic) => ({
      ...topic,
      meetingTranscriptGeneratedItems: topic.meetingTranscriptGeneratedItems.map((subItem) => ({
        ...subItem,
      })),
    }))

    let deletedTopic = tempmMeetingGeneratedItems.find((topic) => topic.umtId === value.umtId)

    if (!deletedTopic) return

    deletedTopic.isDeleted = false
    setSelectedMeetingGeneratedItems(tempmMeetingGeneratedItems)
    setAvailableTopics((prev) => [
      ...prev,
      { umtId: value.umtId, umtDescription: value.umtDescription, goalId: value.goalId },
    ])
  }

  return (
    <>
      {isModalOpen && selectedItem && selectedTopic && (
        <UpdateMeetingTopicModal
          selectedItem={selectedItem}
          currentTopic={selectedTopic}
          closeModal={() => {
            setSelectedItem(null)
            setSelectedTopic(null)
            setIsModalOpen(false)
          }}
          availableTopics={availableTopics}
          transferItemToTopic={transferItemToTopic}
        />
      )}
      {isAssignmentModalOpen &&
        selectedItem &&
        selectedItemIndex !== null &&
        selectedTopicIndex !== null && (
          <AssigneeActivity
            objectiveId={-1}
            setModelView={setIsAssignmentModalOpen}
            assigneeList={selectedItem.assigneeList}
            view={isAssignmentModalOpen}
            placeHolderOnly={true}
            cleanup={(assignees) =>
              handleItemUpdate(
                selectedItemIndex,
                selectedTopicIndex,
                selectedItem.isAccepted,
                assignees,
                false,
                true,
              )
            }
          />
        )}
      {meetingGeneratedItems.length > 0 ? (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {!isFinalized && (
            <div style={{ marginLeft: '0.5rem', marginTop: '2rem' }}>
              {t('transcription.warning')}
            </div>
          )}
          {meetingGeneratedItems
            .sort((a, b) => {
              if (a.umtId === -1) return 1
              if (b.umtId === -1) return -1
              return 0
            })
            .map((topic, index) => {
              return (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      columnGap: '1rem',
                      justifyContent: 'space-between',
                      paddingRight: '0rem',
                      paddingTop: '0.3rem',
                      paddingBottom: '0.3rem',
                      marginTop: '1rem',
                      marginLeft: '0.5rem',
                      // marginLeft:'1%',
                    }}
                    key={topic.umtId}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          flexDirection: 'column',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'column',
                            rowGap: '0.5rem',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              width: '97%',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            <ObjectiveStatement>
                              <span
                                style={{
                                  textDecoration: topic.isDeleted ? 'line-through' : 'none',
                                }}
                              >
                                <b>
                                  {topic.umtId !== -1
                                    ? topic.umtDescription
                                    : t('transcription.notyetOrganized')}
                                </b>
                              </span>
                            </ObjectiveStatement>
                            {!isFinalized && topic.isAddedCurrentMeeting && (
                              <>
                                {!topic.isDeleted ? (
                                  <div
                                    class="fitted-button icon-size"
                                    onClick={() => deleteTopic(topic)}
                                  >
                                    <span
                                      class="material-symbols-outlined icon-size"
                                      style={{ fontSize: '16px' }}
                                    >
                                      delete
                                    </span>
                                  </div>
                                ) : (
                                  <div
                                    class="fitted-button icon-size"
                                    onClick={() => addBackTopic(topic)}
                                  >
                                    <span class="material-symbols-outlined icon-size-l">
                                      add_circle
                                    </span>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                          <div className="underline-meeting"></div>
                        </div>
                        <>
                          <div style={{ marginBottom: '1rem', padding: '0rem 2rem 1rem 2rem' }}>
                            {topic.meetingTranscriptGeneratedItems.map((item, itemIndex) => {
                              return (
                                <>
                                  <div
                                    key={item.id}
                                    className={`${isFinalized ? 'meeting-action-comments-view-finalized' : 'meeting-action-comments-view'}`}
                                    style={{}}
                                  >
                                    {!isFinalized && (
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          alignItems: 'center',
                                        }}
                                        className="tooltip"
                                      >
                                        {topic.umtId !== -1 && (
                                          <>
                                            <input
                                              type="checkbox"
                                              id={item.id}
                                              name={item.id}
                                              checked={item.isAccepted}
                                              onChange={() =>
                                                handleItemUpdate(itemIndex, index, !item.isAccepted)
                                              }
                                              disabled={topic.isDeleted}
                                            />
                                            <span
                                              class="tooltiptext meeting_notes"
                                              style={{
                                                width: 'fit-content',
                                                marginLeft: '-40px',
                                                paddingRight: '1rem',
                                                bottom: '100%',
                                              }}
                                            >
                                              {' '}
                                              {item.isAccepted
                                                ? t('Common:tooltip.accept')
                                                : t('Common:tooltip.reject')}{' '}
                                            </span>
                                          </>
                                        )}
                                      </div>
                                    )}
                                    <div
                                      style={{
                                        backgroundColor:
                                          item.isComment || item.isDecision ? '#EF967A' : '#c6d4ed',
                                        color: 'black',
                                        padding: '0.1rem 0.2rem',
                                        borderRadius: '0.5rem',
                                        // marginLeft: '0.5rem',
                                        // marginBottom: '0.3rem'
                                        margin: 'auto auto',
                                        width: '92%',
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                          fontSize: '13.33px',
                                        }}
                                      >
                                        {item.isDecision
                                          ? t('commentsModal.commentsType.decision')
                                          : item.isComment
                                            ? t('commentsModal.commentsType.comment')
                                            : t('commentsModal.commentsType.action')}
                                      </div>
                                    </div>
                                    <div
                                      style={{ display: 'flex', width: '100%', margin: 'auto 0' }}
                                    >
                                      <div
                                        className="review-generated-topics-list-item"
                                        style={{ width: '100%' }}
                                      >
                                        <TextArea
                                          type="text"
                                          onChange={(e) => {
                                            handleItemUpdate(
                                              itemIndex,
                                              index,
                                              item.isAccepted,
                                              e.target.value,
                                              true,
                                            )
                                          }}
                                          value={removeMentionMarkupFromText(item.description)}
                                          style={{
                                            height: '2rem',
                                            backgroundColor: 'white',
                                            width: '100%',
                                            border: `1px solid ${isFinalized ? 'transparent' : '#77aaff'}`,
                                          }}
                                          disabled={isFinalized}
                                          spellCheck={false}
                                        />
                                      </div>
                                    </div>
                                    {!isFinalized && (
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          columnGap: '0.5rem',
                                          marginLeft: 'auto',
                                          marginTop: 'auto',
                                          marginBottom: 'auto',
                                        }}
                                      >
                                        {!(item.isComment || item.isDecision) && (
                                          <div
                                            style={{ marginTop: 'auto', marginBottom: 'auto' }}
                                            class="fitted-button blue tooltip"
                                            onClick={(e) => {
                                              handleItemEdit(item, topic)
                                              setSelectedItemIndex(itemIndex)
                                              setSelectedTopicIndex(index)
                                              setIsAssignmentModalOpen(true)
                                            }}
                                          >
                                            <span
                                              class="material-symbols-outlined"
                                              // style={{ fontSize: '15px' }}
                                            >
                                              person_add
                                            </span>
                                            <span class="tooltiptext" style={{ fontSize: '16px' }}>
                                              {' '}
                                              {t('Common:tooltip.assignToOthers')}{' '}
                                            </span>
                                          </div>
                                        )}
                                        <div
                                          className="fitted-button blue tooltip"
                                          onClick={() => {
                                            handleItemEdit(item, topic)
                                            setIsModalOpen(true)
                                          }}
                                          style={{ marginTop: 'auto', marginBottom: 'auto' }}
                                        >
                                          <span className="material-symbols-outlined">edit </span>
                                          <span
                                            class="tooltiptext meeting_notes"
                                            style={{
                                              width: 'fit-content',
                                              marginLeft: '-30px',
                                              paddingRight: '1rem',
                                            }}
                                          >
                                            {' '}
                                            {t('Common:tooltip.edit')}{' '}
                                          </span>
                                        </div>
                                      </div>
                                    )}

                                    <div></div>
                                  </div>
                                  <div className="divider"></div>
                                </>
                              )
                            })}
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                </>
              )
            })}
          {!isFinalized && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div
                style={{
                  display: 'flex',
                  gap: '1rem',
                  justifyContent: 'center',
                  marginTop: '1rem',
                }}
              >
                <button
                  className="sleek-button sleek-blue"
                  style={{
                    cursor: 'pointer',
                    height: '2.2rem',
                    backgroundColor: 'white',
                    float: 'right',
                    width: '18%',
                  }}
                  onClick={() => handleFinalizeMeeting()}
                >
                  <span>{t('transcription.finalize')}</span>
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '3rem',
          }}
        >
          {t('transcription.noMeetingItems')}
        </div>
      )}
    </>
  )
}

export default MeetingActionDecision

import React from 'react'
import { Link } from 'react-router-dom'
import { teamMeetingViewSubRoutes, routes } from '../../../../utils/routes'
import '../../../MeetingView/styles.css'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  getCompanyConfigSettings,
  getConsolidatedConfigSettings,
  hasRole,
} from '../../../../utils/auth'

const MeetingViewHeaders = ({ hasF2WCapacity }) => {
  const { t } = useTranslation(['MeetingHub'])
  const location = useLocation()
  const productSettings = getCompanyConfigSettings('productSettings')
  const showKpiReportTabInMh = getCompanyConfigSettings('showKpiReportTabInMh') === true
  const enableSummaryOfObjectives =
    getConsolidatedConfigSettings('enable_summary_of_objectives') === true
  const enableDecisionLog = getCompanyConfigSettings('enableDecisionLog') === true
  const canUseMeetingSummarization =
    getConsolidatedConfigSettings('can_use_meeting_summarization') === true

  const canSeeKpiL2 = getConsolidatedConfigSettings('enable_l2_kpi') === true

  return (
    <div className="meetingview-nav-container">
      <Link
        to={`/${routes.teamMeetingview}/${teamMeetingViewSubRoutes.meetingTopics}`}
        className={`nav-option ${
          location.pathname.endsWith(teamMeetingViewSubRoutes.meetingTopics) ? 'active' : ''
        }`}
      >
        <span className="nav-option-text">{t('tabTitle.meetingTopics')}</span>
      </Link>
      {showKpiReportTabInMh && hasRole('team') && !canSeeKpiL2 && (
        <Link
          to={`/${routes.teamMeetingview}/${teamMeetingViewSubRoutes.kpiReportByLeader}`}
          className={`nav-option ${
            location.pathname.endsWith(teamMeetingViewSubRoutes.kpiReportByLeader) ? 'active' : ''
          }`}
        >
          <span className="nav-option-text">{t('tabTitle.kpiReportByLeader')}</span>
        </Link>
      )}

      {/* {(productSettings || enableSummaryOfObjectives) && (
        <>
          <>
            <Link
              to={`/${routes.teamMeetingview}/${teamMeetingViewSubRoutes.overview}`}
              className={`nav-option ${
                location.pathname.endsWith(teamMeetingViewSubRoutes.overview) ? 'active' : ''
              }`}
            >
              <span className="nav-option-text">
                {productSettings === true
                  ? t('tabTitle.initiativesReport')
                  : t('tabTitle.summaryOfObjectives')}
              </span>
            </Link>
          </>
        </>
      )} */}

      {canUseMeetingSummarization && (
        <Link
          to={`/${routes.teamMeetingview}/${teamMeetingViewSubRoutes.meetingSummaries}`}
          className={`nav-option ${
            location.pathname.endsWith(teamMeetingViewSubRoutes.meetingSummaries) ? 'active' : ''
          }`}
        >
          <span className="nav-option-text">{t('tabTitle.meetingSummaries')}</span>
        </Link>
      )}

      {enableDecisionLog && hasRole('team') && (
        <>
          <Link
            to={`/${routes.teamMeetingview}/${teamMeetingViewSubRoutes.leadershipDecisions}`}
            className={`nav-option ${
              location.pathname.endsWith(teamMeetingViewSubRoutes.leadershipDecisions)
                ? 'active'
                : ''
            }`}
          >
            <span className="nav-option-text">{t('tabTitle.decisionLog')}</span>
          </Link>
        </>
      )}

      {/* <Link
        to={`/${routes.teamMeetingview}/${teamMeetingViewSubRoutes.tradeOffs}`}
        className={`nav-option ${location.pathname.endsWith(teamMeetingViewSubRoutes.tradeOffs) ? 'active' : ''
          }`}
      >
        <span className="nav-option-text">{t('tabTitle.tradeOffs')}</span>
      </Link> */}
      {/* <Link
        to={`/${routes.meetingview}/${teamMeetingViewSubRoutes.dependencyResolution}`}
        className={`nav-option ${
          location.pathname.endsWith(teamMeetingViewSubRoutes.dependencyResolution) ? 'active' : ''
        }`}
      >
        <span className="nav-option-text">Dependency Resolution</span>
      </Link> */}

      {/* <Link
          to={`/${routes.teamMeetingview}/${teamMeetingViewSubRoutes.takeaways}`}
          className={`nav-option ${location.pathname.endsWith(teamMeetingViewSubRoutes.takeaways) ? 'active' : ''
            }`}
        >
          <span className="nav-option-text">{t('tabTitle.meetingNotes')}</span>
        </Link> */}

      {/* <Link
        to={`/${routes.teamMeetingview}/${teamMeetingViewSubRoutes.tasks}`}
        className={`nav-option ${location.pathname.endsWith(teamMeetingViewSubRoutes.tasks) ? 'active' : ''
          }`}
      >
        <span className="nav-option-text">{t('tabTitle.teamLeaderTasks')}</span>
      </Link> */}

      {/* <Link
          to={`/${routes.teamMeetingview}/${teamMeetingViewSubRoutes.myDecisions}`}
          className={`nav-option ${location.pathname.endsWith(teamMeetingViewSubRoutes.myDecisions) ? 'active' : ''
            }`}
        >
          <span className="nav-option-text">{t('tabTitle.myDecisionHistory')}</span>
        </Link> */}
    </div>
  )
}

export default MeetingViewHeaders

import React, { useRef } from 'react'
import '../../pages/MeetingView/components/MilestonesOverview/styles.css'
import useGetObjectiveMilestones from '../../api/query/useGetObjectiveMilestones'
import styled from 'styled-components'
import InPageLoader from '../../components/InPageLoader'
import EmployeeListModal from '../../components/EmployeeListModal'
import CommentsModal2 from '../CommentsModal2'
import { dateFilter, dateToUnix } from '../../utils/time'
import useGetAreaEmployees from '../../api/query/useGetAreaEmployees'
import Select from 'react-select'
import {
  getCompanyConfigSettings,
  hasRole,
  getConsolidatedConfigSettings,
  isAreaLeader,
  isCEO,
  isCXO,
  isCOS,
  isIC,
} from '../../utils/auth'
import useGetObjectivesMeetingTopics from '../../api/query/useGetObjectivesMeetingTopics'
import useManyObjectivesComments from '../../api/query/getManyObjectivesComments'
import useGetObjectivesInformation_v2 from '../../api/query/useGetObjectivesInformation_v2'
import { useOutletContext, useNavigate, useLocation } from 'react-router-dom'
import ObjectivePriorityModal from '../ObjectivePriorityModal'
import ObjectiveRagModal from '../ObjectiveRagModal'
import { useFeatureFlagsContext } from '../../utils/featureFlagsContext'
import useObjectivePriorityUpdate from '../../api/mutation/useObjectivePriorityUpdate'
import useObjectiveStatusUpdate from '../../api/mutation/useObjectiveStatusUpdate'
import AddEditActivityModal from '../AddEditActivityModal'
import { useTranslation } from 'react-i18next'
import useObjectiveRagUpdate from '../../api/mutation/useObjectiveRagUpdate'
import { linkifyString } from '../../utils/general'
import CountObjectivieChartsModal from '../CountObjectiveChartsModal'
import { getHttpRequest } from '../../api/query/dynamicAPI'
import StaffingTableModal from '../StaffingTableModal'
import EmployeeAllocationModal from '../EmployeeAllocationModal'
import { routes } from '../../utils/routes'
import InitNumberModal from '../InitNumberModal'
import CoOwnedObjectiveAllocModal from '../CoOwnedObjectiveAllocModal'
import CompletedObjectivesModal from '../CompletedObjectivesModal'
import Swal from 'sweetalert2'
import { getReportingEmployeesList } from '../../api/services/employee.services'
import ObjectiveDetailsModal from '../../pages/MeetingMultiView/components/MeetingTopics/common/component/OjbectiveDetailsModal'
import { isTeamLeader } from '../../utils/auth'
import { set } from 'lodash'
import { shortenName } from '../../utils/general'
import SooDropdownMenu from '../SooDropdownMenu'
import useEmployeeData_2_3 from '../../api/query/useEmployeeDataLevel2&3'
import RequestUpdateModal from '../../pages/objectiveFlow/modelComponent/requestUpdateModal'
import NotificationsModal from '../../pages/objectiveFlow/modelComponent/notificationsModal'
import ObjMapActivitiesModal from '../../pages/objectiveFlow/modelComponent/activitiesModal'
import { getObjectivesSpreadSheet } from '../../api/services/spreadsheet.srvices'
import fileDownload from 'js-file-download'
import { removeMentionMarkupFromText } from '../../utils/parseMarkup'
import GanttChartModal from '../GanttChartModal/ganttChartModal'
import KpiDropdownMenu from '../KPI/KpiDropdownMenu'
import {
  deleteKpi,
  getKpiList,
  getKpiUpdates,
  updateKpiStatus,
} from '../../api/services/kpi.services'
import KPIUpdatesModal from '../KPI/KPIUpdatesModal'
import UpdateKPIModal from '../KPI/UpdateKPIModal'
import AddEditKPIModal from '../KPI/AddEditKPIModal'
import CardModal from '../CardModal'
import Toast from '../../common/toast'
import ApplicationLayout from '../../components/ApplicationLayout'

const ObjectiveStatement = styled.div`
  font-size: 16px;
  width: -moz-fit-content;
  width: fit-content;
  word-break: break-word;
`
const MilestonesColumnTitle = styled.div`
  font-size: 14px;
  width: -moz-fit-content;
  width: fit-content;
`
const NameBox = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 100%;
  height: 16px;
  width: 16px;
  color: #0926d5;
  border: 2px solid #e4e7fd;
  font-weight: bold;
`

const SummaryOfObjectives = ({ eIdsOfSelectedTls, isMultiMeetingView = false }) => {
  const { t } = useTranslation(['MeetingHub', 'ObjectiveMap', 'Common'])

  const statffingOptions = [
    { value: 'all', label: t('Common:filter.all') },
    { value: 'staffed', label: t('Common:filter.staffed') },
    { value: 'unstaffed', label: t('Common:filter.unstaffed') },
  ]

  const showLinkageSummOfObj = getConsolidatedConfigSettings('show_linkage_summ_of_obj')
  // const { setRefreshIndex, refreshIndex, refreshBGUScorecardFn } = useOutletContext()
  const [refreshIndex, setRefreshIndex] = React.useState(0)

  let tlIdsList = eIdsOfSelectedTls != undefined ? eIdsOfSelectedTls : []
  const [isEmployeeListModalOpen, setIsEmployeeListModalOpen] = React.useState(false)
  const [isCommentsModalOpen, setIsCommentsModalOpen] = React.useState(false)
  const [isActivityModalOpen, setIsActivityModalOpen] = React.useState(false)
  const [iStaffingModalOpen, setStaffingModalOpen] = React.useState(false)
  const [isAllocModalOpen, setIsAllocModalOpen] = React.useState(false)
  const [isInitModalOpen, setInitModalOpen] = React.useState(false)
  const [buttonClicked, setButtonClicked] = React.useState({})
  const productSettings = getCompanyConfigSettings('productSettings')
  // variable only used for TL view of summary of objetives mine vs all obj view
  const [showAll, setShowAll] = React.useState(productSettings && hasRole('ic') ? true : false)

  const [objectiveSelected, setObjectiveSelected] = React.useState(null)
  const [taskIdSelected, setTaskIdSelected] = React.useState(null)
  const [topicIdSelected, setTopicIdSelected] = React.useState(null)
  const [displayAllObjectives, setDisplayAllObjectives] = React.useState(false)
  const [objIdsToShowTitle, setObjIdsToShowTitle] = React.useState({})
  const [staffData, setStaffData] = React.useState(null)
  const { flags } = useFeatureFlagsContext()
  const mutationObjPriorityUpdate = useObjectivePriorityUpdate()
  const mutationObjStatusUpdate = useObjectiveStatusUpdate()
  const mutationObjRagUpdate = useObjectiveRagUpdate()

  const isDelegateTl = parseInt(localStorage.getItem('dType')) === 5 ? true : false
  const canAddEditObjs = getConsolidatedConfigSettings('can_add_edit_objs') || isAreaLeader()
  const canChangeObjStatus =
    getConsolidatedConfigSettings('can_change_obj_status') || isAreaLeader()
  const canChangeObjPriority =
    getConsolidatedConfigSettings('can_change_obj_priority') || isAreaLeader()
  const canChangeInitNumber =
    getConsolidatedConfigSettings('can_change_init_number') || isAreaLeader()

  const [isObjectiveActivityChartsModalOpen, setIsObjectiveActivityChartsModalOpen] =
    React.useState(false)
  const [isShowCompleted, setIsShowCompleted] = React.useState(false)
  const [employeeAlloc, setEmployeeAlloc] = React.useState({})
  const [leadersList, setLeadersList] = React.useState([])
  const [leadersListFilter, setLeadersListFilter] = React.useState([])
  const [objectivesListFilter, setObjectivesListFilter] = React.useState([])
  const [disableLeadersList, setDisableLeadersList] = React.useState(false)
  const [disableObjectivesList, setDisableObjectivesList] = React.useState(false)
  const [showFilteredObjectives, setShowFilteredObjectives] = React.useState(false)
  const [refetchObjectivesInfo, setRefetchObjectivesInfo] = React.useState(false)
  const [isObjDetailsOpen, setIsObjDetailsOpen] = React.useState(false)
  const [isGanttChartModalOpen, setIsGanttChartModalOpen] = React.useState(false)
  const allowAddingL2Activities = getConsolidatedConfigSettings('allow_adding_l2_activities')

  const hideSooAdditionalFilters = getCompanyConfigSettings('hideSooAdditionalFilters') === true

  const [isRequestUpdateModalOpen, setIsRequestModalOpen] = React.useState(false)
  const [isNotificationModalOpen, setIsNotificationModalOpen] = React.useState(false)
  const [isActivitiesModalOpen, setIsActivitiesModalOpen] = React.useState(false)
  const [activitiesModalMode, setActivitiesModalMode] = React.useState('')
  const [updatesList, setUpdatesList] = React.useState([])

  const [topicId, setTopicId] = React.useState(0)
  const [topicType, setTopicType] = React.useState(0)
  const [isTopicModalOpen, setIsTopicModalOpen] = React.useState(false)
  const [disableRefreshHidden, setDisableRefreshHidden] = React.useState(false)

  const {
    data: areaEmployeesData,
    isLoading: isTeamLeaderLoading,
    isFetching: isTeamLeaderFetching,
    error: teamLeaderError,
  } = useGetAreaEmployees()

  const navigate = useNavigate()
  const currentLocation = useLocation()

  const [colors, setColors] = React.useState([
    '#2f4f4f',
    '#800000',
    '#008000',
    '#4b0082',
    '#ff8c00',
    '#0000ff',
    '#ff00ff',
    '#1e90ff',
    '#ff69b4',
  ])
  const [employeesWithColors, setEmployeesWithColors] = React.useState([])
  const [milestonesPeopleList, setMilestonesPeopleList] = React.useState([])
  const statusOptions = [
    { value: 'NEW', label: 'Pre-PD0' },
    { value: 'NOT_STARTED', label: 'PD0' },
    { value: 'IN_PROGRESS', label: 'PD1' },
    { value: 'COMPLETE', label: 'PDf' },
    { value: 'DEPRIORITIZED', label: 'PDx' },
    { value: 'UNKNOWN_PROGRESS', label: '' },
  ]
  const [rags, setRags] = React.useState({
    GREEN: 0,
    YELLOW: 1,
    RED: 2,
  })

  const statuses = [
    { status: 'Pre-PD0', id: 'NEW' },
    { status: 'PD0', id: 'NOT_STARTED' },
    { status: 'PD1', id: 'IN_PROGRESS' },
    { status: 'PDf', id: 'COMPLETE' },
    { status: 'PDx', id: 'DEPRIORITIZED' },
  ]

  const [milestonesOverviewData, setMilestonesOverviewData] = React.useState({
    milestones: [],
    // blockers: [],
    objectives: [],
  })

  const {
    data: objectivesInformation,
    isLoading: isObjectiveInformationLoading,
    isFetching: isFetchingObjectiveInformation,
  } = useGetObjectivesInformation_v2(
    leadersListFilter?.eId,
    objectivesListFilter?.id,
    isShowCompleted,
    showFilteredObjectives,
    refetchObjectivesInfo,
    productSettings ? showAll : false,
  )

  const { data: commentData, isLoading: isCommentLoading } = useManyObjectivesComments({
    objIds: objectivesInformation?.objectives?.map((obj) => parseInt(obj?.id)) ?? [],
    pinned: true,
    isDecision: true,
    showIsCompleted: isShowCompleted,
  })
  const {
    data: topicsData,
    isLoading: isTopicsDataLoading,
    isFetching: isFetchingTopicsData,
  } = useGetObjectivesMeetingTopics(
    objectivesInformation?.objectives?.map((obj) => parseInt(obj?.id)) ?? [],
  )
  const {
    data: milestoneData,
    isLoading: isMilestonesLoading,
    isFetching: isFetchingMilestones,
  } = useGetObjectiveMilestones(
    objectivesInformation?.objectives?.map((obj) => parseInt(obj?.id)) ?? [],
  )
  const initiativesData = [...new Set(objectivesInformation?.objectives?.map((obj) => obj))]

  const [teamLeaders, setTeamLeaders] = React.useState([])
  const { data: dataEmpLevel23 } = useEmployeeData_2_3()

  React.useEffect(() => {
    //fix this so list gets filtered
    // // Get an array of eIds from the objectivesInformation array
    // const eIdsInObjectives = objectivesInformation?.objectives.map((objective) => parseInt(objective.ownerId))

    // // Filter employees whose eId is in the eIdsInObjectives array
    // const filteredEmployees = dataEmpLevel23?.employees.filter((employee) =>
    //   eIdsInObjectives?.includes(employee.eId)
    // )
    // setTeamLeaders(filteredEmployees)
    setTeamLeaders(dataEmpLevel23?.employees)
  }, [dataEmpLevel23])

  const [objectivesCopy, setObjectivesCopy] = React.useState([])
  const [milestonesCopy, setMilestonesCopy] = React.useState([])
  const [timeframeFilterValue, setTimeframeFilterValue] = React.useState('all')
  const [peopleFilterValue, setPeopleFilterValue] = React.useState([])
  const [initiativeFilterValue, setInitiativeFilterValue] = React.useState([])
  const [objectiveFilterValue, setObjectiveFilterValue] = React.useState([])
  const [statusFilterValue, setStatusFilterValue] = React.useState([])
  const [objectiveOwnerValue, setObjectiveOwnerValue] = React.useState([])
  const [staffingFilterValue, setStaffingFilterValue] = React.useState(statffingOptions[0])
  const [tagFilterValue, setTagFilterValue] = React.useState([])
  // const [filteredObjectives, setFilteredObjectives] = React.useState([])
  const [cardModalUmtId, setCardModalUmtId] = React.useState(0)
  const [cardModalTopicType, setCardModalTopicType] = React.useState(null)

  const [isCoOwnedObjectiveAllocModalOpen, setIsCoOwnedObjectiveAllocModalOpen] =
    React.useState(false)
  const [isCompletedObjectivesModalOpen, setIsCompletedObjectivesModalOpen] = React.useState(false)
  const [isRagModalOpen, setIsRagModalOpenn] = React.useState(false)

  const [isObjectivePriorityModalOpen, setIsObjectivePriorityModalOpen] = React.useState(false)

  const [singleObj, setSingleObj] = React.useState(null)
  const [selectedKpiUpdate, setSelectedKpiUpdate] = React.useState(null)
  const [isOpenKPIUpdatesModal, setIsOpenKPIUpdatesModal] = React.useState(false)
  const [objId, setObjId] = React.useState(null)
  const [isOpenUpdateModal, setIsOpenUpdateModal] = React.useState(false)
  const [selectedKpi, setSelectedKpi] = React.useState(null)
  const [addEditModalMode, setAddEditModalMode] = React.useState('')
  const [isOpenAddEditKPIModal, setIsOpenAddEditKPIModal] = React.useState(false)

  const tagsOptions = React.useMemo(() => {
    let tags = []
    for (let obj of initiativesData) {
      if (obj.tags) {
        for (let tag of obj.tags) {
          if (!tags.some((t) => t.tagId === tag.tagId)) {
            tags.push(tag)
          }
        }
      }
    }
    return tags.map((tag) => ({ value: tag.tagId, label: tag.tagName }))
  }, [initiativesData])

  const timeframeOptions = [
    { value: 'all', label: t('Common:filter.all') },
    { value: '7days', label: t('Common:filter.nextWeek') },
    { value: '14days', label: t('Common:filter.next2Weeks') },
    { value: '28days', label: t('Common:filter.next4Weeks') },
    { value: 'overdue', label: t('Common:filter.overdue') },
  ]

  const isLoading =
    isObjectiveInformationLoading ||
    (isFetchingObjectiveInformation && isMilestonesLoading) ||
    (isFetchingMilestones && isTopicsDataLoading) ||
    (isFetchingTopicsData && isTeamLeaderLoading) ||
    isTeamLeaderFetching

  const isLoadingFromChatbot =
    isObjectiveInformationLoading ||
    isFetchingObjectiveInformation ||
    isMilestonesLoading ||
    isFetchingMilestones ||
    isTopicsDataLoading ||
    isFetchingTopicsData ||
    isTeamLeaderLoading ||
    isTeamLeaderFetching

  React.useEffect(() => {
    getReportingEmployees()
  }, [])

  const useSingleObj = useRef(false)

  React.useEffect(() => {
    // 1. we need isLoadingFromChatbot, here otherwise milestonesOverviewData
    // will be overwritten by other data (for example, useGetObjectivesInformation_v2) when they fetched;
    // 2. ref is neeed... to ensure this function is called once only..
    if (
      !isLoadingFromChatbot &&
      !useSingleObj.current &&
      currentLocation?.state?.objId &&
      initiativesData.length > 0 &&
      objectiveFilterValue.length === 0
    ) {
      let option = initiativesData.find((obj) => obj.id == currentLocation.state.objId)
      if (option) {
        // we need an intermediate value to store the option;
        setSingleObj(option)
      }
      useSingleObj.current = true
    }
  }, [initiativesData, isLoadingFromChatbot, objectiveFilterValue])

  React.useEffect(() => {
    if (areaEmployeesData?.employees) {
      let tempAreaEmployeeData = [...(areaEmployeesData?.employees ?? [])]
      tempAreaEmployeeData.unshift({ name: t('Common:filter.unassigned'), eId: null })
      setMilestonesPeopleList(tempAreaEmployeeData)

      let tempEmployeesWithColors = areaEmployeesData?.employees.map((employee, index) => {
        let color = colors[index % colors.length]
        return { ...employee, color }
      })
      setEmployeesWithColors([...tempEmployeesWithColors])
    }
  }, [areaEmployeesData])

  React.useEffect(() => {
    setMilestonesOverviewData({
      milestones: milestoneData?.milestones ?? [],
      topics: topicsData?.blockedObjectives ?? [],
      objectives: objectivesInformation?.objectives ?? [],
    })
    if (objectivesInformation != undefined && objectivesInformation?.objectives?.length > 0)
      updateObjIdsListToShowTitle(objectivesInformation?.objectives)
    setObjectivesCopy(objectivesInformation?.objectives ?? [])
    setMilestonesCopy(milestoneData?.milestones ?? [])

    if (objectivesInformation?.objectives !== undefined) {
      for (let obj of objectivesInformation.objectives) {
        setButtonClicked((prev) => ({
          ...prev,
          [obj.id]: rags[obj.rag],
        }))
      }
    }
  }, [
    milestoneData?.milestones,
    isFetchingMilestones,
    isFetchingObjectiveInformation,
    isFetchingTopicsData,
    // realoadTracker
  ])

  // React.useEffect(() => {
  //   if (selectedKpiUpdate?.kpiId) {
  //     loadUpdates(selectedKpiUpdate.kpiId)
  //   }
  // }, [selectedKpiUpdate])

  // async function loadUpdates(kpiId) {
  //   // setIsLoading(true)

  //   let result = await getKpiUpdates(kpiId)
  //   // setIsLoading(false)
  //   if (result && result.kpiUpdates) {
  //     setUpdatesList(result.kpiUpdates)
  //   }
  // }

  const [isHidden, setIsHidden] = React.useState(() =>
    (objectivesInformation?.objectives || []).map(() => ({
      overview: true,
      comment: true,
      bgu: true,
      action: true,
      kpi: true,
    })),
  )

  const toggleContent = (index) => {
    console.log('Before update:', isHidden)
    setIsHidden((prevIsHidden) => {
      const newIsHidden = prevIsHidden.map((item, i) =>
        i === index ? { ...item, overview: !item.overview } : item,
      )
      console.log('After update:', newIsHidden)
      return newIsHidden
    })
  }

  const toggleComment = (index) => {
    console.log('Before update:', isHidden)
    setIsHidden((prevIsHidden) => {
      const newIsHidden = prevIsHidden.map((item, i) =>
        i === index ? { ...item, comment: !item.comment } : item,
      )
      console.log('After update:', newIsHidden)
      return newIsHidden
    })
  }

  const toggleBGU = (index) => {
    console.log('Before update:', isHidden)
    setIsHidden((prevIsHidden) => {
      const newIsHidden = prevIsHidden.map((item, i) =>
        i === index ? { ...item, bgu: !item.bgu } : item,
      )
      console.log('After update:', newIsHidden)
      return newIsHidden
    })
  }

  const toggleAction = (index) => {
    console.log('Before update:', isHidden)
    setIsHidden((prevIsHidden) => {
      const newIsHidden = prevIsHidden.map((item, i) =>
        i === index ? { ...item, action: !item.action } : item,
      )
      console.log('After update:', newIsHidden)
      return newIsHidden
    })
  }

  const toggleKpi = (index) => {
    console.log('Before update:', isHidden)
    setIsHidden((prevIsHidden) => {
      const newIsHidden = prevIsHidden.map((item, i) =>
        i === index ? { ...item, kpi: !item.kpi } : item,
      )
      console.log('After update:', newIsHidden)
      return newIsHidden
    })
  }

  const [isFilterSectionHidden, setIsFilterSectionHidden] = React.useState(true)

  const toggleFilterSection = () => {
    setIsFilterSectionHidden(!isFilterSectionHidden)
  }

  const [isDeepDiveSectionHidden, setIsDeepDiveSectionHidden] = React.useState(false)

  const toggleDeepDiveSection = () => {
    setIsDeepDiveSectionHidden(!isDeepDiveSectionHidden)
  }

  React.useEffect(() => {
    if (milestonesOverviewData?.objectives && !disableRefreshHidden) {
      setIsHidden(
        new Array(milestonesOverviewData?.objectives.length).fill({
          overview: true,
          comment: true,
          bgu: true,
          action: true,
          kpi: true,
        }),
      )
    }
  }, [milestonesOverviewData?.objectives])

  const collapseAll = () => {
    setIsHidden(
      new Array(objectivesInformation?.objectives?.length).fill({
        overview: true,
        comment: true,
        bgu: true,
        action: true,
        kpi: true,
      }),
    )
  }

  const uncollapseAll = () => {
    setIsHidden(
      new Array(objectivesInformation?.objectives?.length).fill({
        overview: false,
        comment: false,
        bgu: false,
        action: false,
        kpi: false,
      }),
    )
  }

  const shouldCollapse = isHidden.some((item) => item.overview === false)

  // Deep dive filter should not be available to ALs and TLs, even if they are enterprise users.
  // const showDeepDive = !(hasRole('area') || hasRole('team'))
  //TODO Swetha: Disabling it temporarily
  const showDeepDive = false

  // React.useEffect(()=>{
  //     if(Object.keys(employeeAlloc).length !== 0){
  //         setIsAllocModalOpen(true)
  //     }
  // }, [employeeAlloc])

  const updateObjIdsListToShowTitle = (objectives) => {
    const objIds = {}
    objectives.forEach((data) => {
      const id = parseInt(data?.id)
      const originalId = data?.originalId
      if (originalId != undefined) {
        if (!objIds.hasOwnProperty(originalId)) {
          objIds[originalId] = id
        }
      } else {
        if (!objIds.hasOwnProperty(id)) {
          objIds[id] = id
        }
      }
    })
    setObjIdsToShowTitle(objIds)
  }

  const applyTimeFrameFilter = () => {
    let result
    switch (timeframeFilterValue.value) {
      case 'all':
        result = timeframeFilterAll()
        break

      case '7days':
        result = timeframeFilterDays(7)
        break

      case '14days':
        result = timeframeFilterDays(14)
        break

      case '28days':
        result = timeframeFilterDays(28)
        break

      case 'overdue':
        result = timeframeFilterDays(-1)
        break

      default:
        result = timeframeFilterAll()
        break
    }
    return result
  }

  const applyFilters = () => {
    let milestones, objectives, result

    result = applyTimeFrameFilter()
    milestones = result[0]
    objectives = result[1]

    result = peopleFilter(milestones, objectives)
    milestones = result[0]
    objectives = result[1]

    result = initiativeFilter(milestones, objectives)
    milestones = result[0]
    objectives = result[1]

    result = statusFilter(milestones, objectives)
    milestones = result[0]
    objectives = result[1]

    result = objectiveOwnerFilter(milestones, objectives)
    milestones = result[0]
    objectives = result[1]

    result = applyStaffingFilter(milestones, objectives)
    milestones = result[0]
    objectives = result[1]

    result = applyTagsFilter(milestones, objectives)
    milestones = result[0]
    objectives = result[1]

    setMilestonesOverviewData({
      milestones: [...milestones],
      // blockers: blockersData.blockedObjectives,
      objectives: [...objectives],
    })
    updateObjIdsListToShowTitle(objectives)

    collapseAll()
  }

  const openEmployeeListModal = () => {
    setIsEmployeeListModalOpen(true)
  }

  const closeEmployeeListModal = () => {
    setIsEmployeeListModalOpen(false)
  }

  const openCommentsModal = () => {
    setIsCommentsModalOpen(true)
  }

  const closeCommentsModal = () => {
    setIsCommentsModalOpen(false)
    setObjectiveSelected(null)
    setTopicIdSelected(null)
    setTaskIdSelected(null)
    setDisplayAllObjectives(false)
  }

  const getStringEmployees = (list, type) => {
    let result = ''
    let name = ''
    for (let [index, emp] of list.entries()) {
      if (type === 'activities') {
        name = emp.name
      } else if (type === 'objectives') {
        name = emp.eName
      }
      if (index === 0) {
        result = name
      } else if (index === list.length - 1) {
        result += ' & ' + name
      } else {
        result += ', ' + name
      }
    }
    if (type === 'activities') {
      return '( ' + result + ' )'
    } else return result
  }

  const closeCoOwnedObjectiveAllocModal = () => {
    setIsCoOwnedObjectiveAllocModalOpen(false)
    setObjectiveSelected(null)
  }
  const closeObjectivePriorityModal = () => {
    setIsObjectivePriorityModalOpen(false)
    setObjectiveSelected(null)
  }
  const closeObjectiveRagModal = () => {
    setIsRagModalOpenn(false)
    setObjectiveSelected(null)
  }

  function handleChangeTimeframeFilter(value) {
    setTimeframeFilterValue(value)
  }

  function handleChangeStaffingFilter(value) {
    setStaffingFilterValue(value)
  }

  function handleChangeTagFilter(value) {
    setTagFilterValue(value)
  }

  const handleChangePeopleFilter = (value) => {
    setPeopleFilterValue(value)
  }

  const handleChangeInitiativeFilter = (value) => {
    setInitiativeFilterValue(value)
  }

  const handleChangeObjectiveFilter = (value) => {
    setObjectiveFilterValue(value)
  }

  const handleChangeStatusFilter = (value) => {
    setStatusFilterValue(value)
  }

  const handleChangeObjectiveOwnerFilter = (value) => {
    setObjectiveOwnerValue(value)
  }

  const timeframeFilterAll = () => {
    return [[...milestonesCopy], [...objectivesCopy]]
  }

  const timeframeFilterDays = (days) => {
    let milestones = []
    let objectives = []
    let limitDate = new Date()
    limitDate.setDate(limitDate.getDate() + days)
    limitDate.setHours(23, 59, 59, 999)

    for (let mil of milestonesCopy) {
      let dueDate = new Date(mil.dueDate * 1000)

      if (mil.dueDate > 0 && dateToUnix(dueDate) <= dateToUnix(limitDate)) {
        milestones.push(mil)
      }
    }
    const objSet = new Set()
    for (let obj of objectivesCopy) {
      for (let mil of milestones) {
        if (parseInt(obj.id) === parseInt(mil.objectiveId) && !objSet.has(obj.id)) {
          objectives.push(obj)
          objSet.add(obj.id)
        }
      }
    }

    return [milestones, objectives]
  }

  const peopleFilter = (milestones, objectives) => {
    if (peopleFilterValue.length === 0) {
      return [milestones, objectives]
    }

    const filteredMilestones = milestones?.filter((milestone) =>
      milestone.assigneeList.some((assignee) =>
        peopleFilterValue.some((leader) => leader.eId === assignee.eId),
      ),
    )

    let objectivesList = []
    const objSet = new Set()
    for (let obj of objectives) {
      for (let mil of filteredMilestones) {
        if (parseInt(obj.id) === parseInt(mil.objectiveId) && !objSet.has(obj.id)) {
          objectivesList.push(obj)
          objSet.add(obj.id)
        }
      }
    }

    return [filteredMilestones, objectivesList]
  }

  const initiativeFilter = (milestones, objectives) => {
    if (objectiveFilterValue.length === 0) {
      return [milestones, objectives]
    }
    const filteredObjectives = objectives.filter((obj) =>
      objectiveFilterValue.some((objective) => objective.id == obj.id),
    )

    return [milestones, filteredObjectives]
  }

  const objectiveOwnerFilter = (milestones, objectives) => {
    if (objectiveOwnerValue.length === 0) {
      return [milestones, objectives]
    }

    const filteredObjectives = objectives.filter((obj) =>
      objectiveOwnerValue.some((person) => parseInt(person.eId) == parseInt(obj.ownerId)),
    )
    return [milestones, filteredObjectives]
  }

  const applyStaffingFilter = (milestones, objectives) => {
    let filteredObjectives = []
    switch (staffingFilterValue.value) {
      case 'all':
        return [milestones, objectives]
      case 'unstaffed':
        filteredObjectives = objectives.filter(
          (obj) =>
            (!obj.objIcMappings || obj.objIcMappings.length === 0) && !obj.isGeneralObjective,
        )
        break
      case 'staffed':
        filteredObjectives = objectives.filter(
          (obj) => obj.objIcMappings && obj.objIcMappings.length > 0 && !obj.isGeneralObjective,
        )
        break
      default:
        break
    }

    return [milestones, filteredObjectives]
  }

  const statusFilter = (milestones, objectives) => {
    if (statusFilterValue.length === 0) {
      return [milestones, objectives]
    }

    const filteredObjectives = objectives.filter((obj) =>
      statusFilterValue.some((status) => status.id == obj.status),
    )
    return [milestones, filteredObjectives]
  }

  const applyTagsFilter = (milestones, objectives) => {
    if (tagFilterValue.length === 0) {
      return [milestones, objectives]
    }
    console.log('objectives', objectives)
    const filteredObjectives = objectives.filter(
      (obj) =>
        obj.tags &&
        obj.tags.length > 0 &&
        tagFilterValue.some((filterTag) =>
          obj.tags.some((objTag) => objTag.tagId === filterTag.value),
        ),
    )
    console.log('filteredObjectives', filteredObjectives)
    return [milestones, filteredObjectives]
  }

  const handleObjStatusChange = async (status, objective) => {
    let runMutation = true
    if (status.label === 'PDx' || status.label === 'PDf') {
      let result = await Swal.fire({
        title: t('Common:modalMessages.areYouSureYouWantToChangeTheStatusToPDfPDx', {
          X: status.label,
        }),
        showCancelButton: true,
        confirmButtonText: t('Common:commonButtons.yes'),
        cancelButtonText: t('Common:commonButtons.no'),
      })

      if (result.isConfirmed !== null) {
        runMutation = result.isConfirmed
      }
    }

    if (runMutation === true) {
      await mutationObjStatusUpdate.mutateAsync(
        {
          objective: objective,
          newStatus: status.value,
        },
        {
          onSuccess: (response) => {
            if (response) {
              setRefreshIndex(refreshIndex + 1)
            } else {
            }
          },
        },
      )
    }
  }
  const handleObjPriorityChange = async (initiativeId, priority) => {
    await mutationObjPriorityUpdate.mutateAsync(
      {
        initiativeId: initiativeId,
        priority: priority,
      },
      {
        onSuccess: (response) => {
          if (response) {
            closeObjectivePriorityModal()
            setRefreshIndex(refreshIndex + 1)
          } else {
          }
        },
      },
    )
  }
  const handleRagChange = async (obj, id) => {
    setButtonClicked((prev) => ({
      ...prev,
      [obj.id]: parseInt(id),
    }))
    let rag = getKeyByValue(rags, id)
    await mutationObjRagUpdate.mutateAsync({
      rag,
      objectiveId: obj.id,
    })
  }

  const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value)
  }
  // year-month-day => month/day/year
  const formatDueDate = (dueDate) =>
    ((dates) => `${dates[1]}/${dates[2]}/${dates[0]}`)(dueDate?.split('-'))

  const getEmployeeAlloc = async () => {
    let url = '/emp_obj_alloc?get-all-alloc=true'
    let allocs = await getHttpRequest(url)
    let allocMaps = {}
    allocs?.objIcMappings?.forEach((empAlloc) => {
      if (!allocMaps[empAlloc.eName]) {
        allocMaps[empAlloc.eName] = []
      }
      allocMaps[empAlloc.eName].push({
        objDesc: empAlloc.objDesc,
        eName: empAlloc.eName,
        allocation: empAlloc.allocation,
      })
    })
    setEmployeeAlloc(allocMaps)
    setIsAllocModalOpen(true)
  }

  const handleStaffingTableClick = async () => {
    let url = '/get_staffing_table'
    let results = await getHttpRequest(url)
    setStaffingModalOpen(true)
    setStaffData(results)
  }

  const canBeDisabledNoCommentsObj = (obj) => (obj.totalCommentsCount > 0 ? '' : 'inactive-blue')
  const canBeDisabledNoCommentsMls = (mls) => (mls.totalCommentsCount > 0 ? '' : 'inactive-blue')

  const handleInitModalClick = () => {
    setInitModalOpen(true)
  }

  async function getReportingEmployees() {
    const result = await getReportingEmployeesList()
    if (result?.employees) setLeadersList(result.employees)
  }

  const handleChangeLeadersList = (value) => {
    setLeadersListFilter(value)
    value.length === 0 ? setDisableObjectivesList(false) : setDisableObjectivesList(true)
  }

  const handleChangeObjectiveListFilter = (value) => {
    setObjectivesListFilter(value)
    value.length === 0 ? setDisableLeadersList(false) : setDisableLeadersList(true)
  }

  const clearFilters = () => {
    setLeadersListFilter([])
    setObjectivesListFilter([])
    setShowFilteredObjectives(false)
    setDisableLeadersList(false)
    setDisableObjectivesList(false)
    setPeopleFilterValue([])
    setTimeframeFilterValue('all')
    setInitiativeFilterValue([])
    setObjectiveFilterValue([])
    setStatusFilterValue([])
    setStaffingFilterValue(statffingOptions[0])
    setRefetchObjectivesInfo((prev) => !prev)
  }

  const onClickEdit = (obj) => {
    setObjectiveSelected(obj)
    navigate(`/${routes.demandintake}`, {
      state: {
        editObj: true,
        objective: obj,
        path: currentLocation.pathname,
        ownerId: obj.ownerId,
      },
    })
  }

  const onClickComment = (obj) => {
    setObjectiveSelected(obj)
    setDisplayAllObjectives(true)
    openCommentsModal()
  }

  const onClickAssignees = (obj) => {
    setObjectiveSelected(obj)
    setIsCoOwnedObjectiveAllocModalOpen(true)
  }

  const onClickRequestUpdate = (obj) => {
    setObjectiveSelected(obj)
    setIsRequestModalOpen(true)
  }

  const onClickHistory = (obj) => {
    setObjectiveSelected(obj)
    setIsObjDetailsOpen(true)
  }

  async function getObjSpreadsheet() {
    const result = await getObjectivesSpreadSheet(
      leadersListFilter?.eId,
      objectivesListFilter?.id,
      isShowCompleted,
      showFilteredObjectives,
      showAll,
    )

    //const random = Math.floor(10000000 + Math.random() * 90000000); // Ensures at least 8 digits leaving here for now if we change our mind
    const today = new Date()
    const mm = String(today.getMonth() + 1).padStart(2, '0')
    const dd = String(today.getDate()).padStart(2, '0')
    const yyyy = today.getFullYear()
    let fileName = 'export_objectives_' + `${mm}-${dd}-${yyyy}` + '.xlsx'

    if (result) {
      fileDownload(result, fileName)
    }
  }

  const convertMilestoneEnumToText = (enumValue) => {
    switch (enumValue) {
      case 1:
        return t('Common:kpi.notStarted')
      case 2:
        return t('Common:kpi.inProgress')
      case 3:
        return t('Common:kpi.completed')
    }
  }

  const convertFrequencyEnumToText = (enumValue) => {
    switch (enumValue) {
      case 1:
        return t('Common:kpi.weekly')
      case 2:
        return t('Common:kpi.biweekly')
      case 3:
        return t('Common:kpi.monthly')
      default:
        return t('Common:kpi.unknown')
    }
  }

  const reload = async (objectiveId = null) => {
    setDisableRefreshHidden(true)
    await loadKpisList(objectiveId)
    setDisableLeadersList(false)
    // reloadObjectiveReportModal()
  }

  async function loadKpisList(objectiveId = null) {
    // isLoading = true
    let result = await getKpiList(objId ? objId : objectiveId)
    // setIsLoading(false)
    if (result && result.kpis) {
      let copyMileStonesOverViewData = JSON.parse(JSON.stringify(milestonesOverviewData))
      let updateDatedObj = copyMileStonesOverViewData.objectives.find(
        (obj) => obj.id === (objId ? objId : objectiveId),
      )
      updateDatedObj.kpis = result.kpis
      setMilestonesOverviewData(copyMileStonesOverViewData)
      // let copyFilteredObjectives = JSON.parse(JSON.stringify(filteredObjectives))
      // let updateDatedObj2 = copyFilteredObjectives.find(obj => obj.id === objId)
      // updateDatedObj2.kpis = result.kpis
      // setFilteredObjectives(copyFilteredObjectives)
    }
    // if (result && result.objDescription) {
    //   // setTitle(result.objDescription)
    //   console.log('Title:', result.objDescription)
    // }
  }

  const handleShowMore = (objId) => {
    console.log('obj:', objId)
    if (hasRole('team')) {
      navigate(`/${routes.teamMeetingview}`, {
        state: { obj: objId },
      })
    } else {
      navigate(`/${routes.meetingMultiView}`, {
        state: { obj: objId },
      })
    }
  }

  const handleClickTopic = (umtId, topicType) => {
    setTopicId(umtId)
    setTopicType(topicType)
    setIsTopicModalOpen(true)
  }

  const getActionBox = (topicType) => {
    let type
    let color
    if (topicType === 1) {
      type = 'blocker'
      color = 'rgb(237, 82, 46)'
    }
    if (topicType === 2) {
      type = 'decision'
      color = 'blue'
    }
    if (topicType === 3) {
      type = 'update'
      color = 'gray'
    }
    if (topicType === 4) {
      type = 'admin'
      color = '#9933FF'
    }
    return (
      <span className="action-box-new-format" style={{ backgroundColor: color }}>
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '8px',
          }}
        >
          {t(`Common:filter.${type}`)}
        </span>
      </span>
    )
  }

  const updateComplete = async (kpi, objectiveId) => {
    console.log('kpi:', kpi)
    console.log('obj', objId)
    let formData = new FormData()
    formData.append('kpiId', kpi.kpiId)
    formData.append(
      'isCompleted',
      kpi?.isCompleted === undefined || kpi?.isCompleted === null ? true : !kpi?.isCompleted,
    )
    try {
      // setIsLoading(true)
      let result = await updateKpiStatus(formData)
      if (result.success) {
        reload(objectiveId)
      } else {
        Toast.fire({
          icon: 'error',
          title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
        })
      }
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
    // setIsLoading(false)
  }

  const deleteKpiFn = async (kpi, objectiveId) => {
    const confirmBoxResult = await Swal.fire({
      title: t('Common:kpi.sureDeleteKpi'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('Common:commonButtons.yes'),
      cancelButtonText: t('Common:commonButtons.no'),
    })

    if (!confirmBoxResult.isConfirmed) {
      return
    }

    let formData = new FormData()
    formData.append('kpiId', kpi.kpiId)

    // setIsLoading(true)
    let result = await deleteKpi(formData)
    // setIsLoading(false)
    reload(objectiveId)

    if (result) {
    } else {
      Toast.fire({
        icon: 'error',
        title: t('Common:modalMessages.somethingWentWrongTryAgainLater'),
      })
    }
  }

  const getAllTopicsData = (objId) => {
    let allTopics = topicsData?.objectives?.filter((tps) => parseInt(objId) === parseInt(tps.objId))
    let allBGUTopics = []
    for (let topic of allTopics) {
      if (topic.blockers) {
        allBGUTopics.push(topic.blockers[0])
      }
      if (topic.decisions) {
        allBGUTopics.push(topic.decisions[0])
      }
      if (topic.updates) {
        allBGUTopics.push(topic.updates[0])
      }
    }
    allBGUTopics.sort((a, b) => {
      return a.topicType - b.topicType
    })
    return allBGUTopics
  }

  React.useEffect(() => {
    if (singleObj) {
      setObjectiveFilterValue([singleObj])
    }
  }, [singleObj])

  React.useEffect(() => {
    if (singleObj !== null && objectiveFilterValue.length > 0) {
      applyFilters()
      setSingleObj(null)
    }
  }, [objectiveFilterValue])

  if (isFetchingMilestones && isFetchingObjectiveInformation && isFetchingTopicsData) {
    return <InPageLoader />
  }

  return (
    <>
      <ApplicationLayout>
        {isTopicModalOpen === true && topicId !== 0 && topicType !== 0 && (
          <CardModal
            isModalOpen={isTopicModalOpen}
            closeModal={() => {
              setIsTopicModalOpen(false)
              setTopicId(0)
              setTopicType(0)
              // refreshBGUScorecardFn()
            }}
            umtId={topicId}
            topicType={topicType}
          />
        )}

        {isOpenAddEditKPIModal && (
          <AddEditKPIModal
            closeModal={() => setIsOpenAddEditKPIModal(false)}
            mode={addEditModalMode}
            objId={objId}
            kpi={selectedKpi}
            reload={() => {
              reload()
              // reloadBackground()
            }}
          />
        )}

        {isOpenKPIUpdatesModal && (
          <KPIUpdatesModal
            handleCancel={() => setIsOpenKPIUpdatesModal(false)}
            reloadBackground={() => {
              reload()
            }}
            selectedKpiUpdate={selectedKpiUpdate}
            objId={objId}
            canAddUpdate={selectedKpiUpdate?.isCompleted === false}
            canEditUpdate={selectedKpiUpdate?.isCompleted === false}
          />
        )}

        {isOpenUpdateModal && (
          <UpdateKPIModal
            closeModal={() => setIsOpenUpdateModal(false)}
            objId={objId}
            kpi={selectedKpiUpdate}
            loadUpdates={() => {
              reload()
              // reloadBackground()
            }}
          />
        )}

        {isActivityModalOpen === true && objectiveSelected !== null && (
          <AddEditActivityModal
            isModalOpen={objectiveSelected.id}
            closeModal={() => {
              setIsActivityModalOpen(false)
              setTaskIdSelected(null)
              setObjectiveSelected(null)
            }}
            objectiveId={objectiveSelected.id}
            activityId={taskIdSelected}
            setIsRefreshObjective={() => {
              setRefreshIndex(refreshIndex + 1)
            }}
            refreshBGUScorecardFn={() => {}}
          />
        )}

        {isCommentsModalOpen === true && (
          <CommentsModal2
            objective={{ id: objectiveSelected.id, statement: objectiveSelected?.statement }}
            handleCancel={closeCommentsModal}
            taskId={taskIdSelected}
            umtId={topicIdSelected}
            displayAllComments={displayAllObjectives}
            showIsCompleted={isShowCompleted}
            setIsRefreshObjective={() => {
              setRefreshIndex(refreshIndex + 1)
            }}
          />
        )}

        {isEmployeeListModalOpen === true && (
          <EmployeeListModal
            title={t('Common:employeeListModal.employees')}
            isModalOpen={isEmployeeListModalOpen}
            closeModal={closeEmployeeListModal}
            objective={objectiveSelected}
          />
        )}

        {isCoOwnedObjectiveAllocModalOpen === true && objectiveSelected && (
          <CoOwnedObjectiveAllocModal
            objective={objectiveSelected}
            isModalOpen={isCoOwnedObjectiveAllocModalOpen}
            closeModal={closeCoOwnedObjectiveAllocModal}
            productSettings={productSettings}
          />
        )}

        {isObjectivePriorityModalOpen === true && objectiveSelected && (
          <ObjectivePriorityModal
            objective={objectiveSelected}
            isModalOpen={isObjectivePriorityModalOpen}
            closeModal={closeObjectivePriorityModal}
            handleSubmit={handleObjPriorityChange}
          />
        )}
        {isRagModalOpen === true && productSettings && objectiveSelected && (
          <ObjectiveRagModal
            objective={objectiveSelected}
            isModalOpen={isRagModalOpen}
            closeModal={closeObjectiveRagModal}
            buttonClicked={buttonClicked}
            handleSubmit={handleRagChange}
            button_num={rags[objectiveSelected.rag]}
          />
        )}

        {isCompletedObjectivesModalOpen && (
          <CompletedObjectivesModal
            isModalOpen={isCompletedObjectivesModalOpen}
            closeModal={() => {
              setIsCompletedObjectivesModalOpen(false)
            }}
          />
        )}
        {/* {isSuggestObjectiveModalOpen === true && (
                <SuggestObjectiveModal
                    isSuggestObjectiveModalOpen={isSuggestObjectiveModalOpen}
                    setIsSuggestObjectiveModalOpen={setIsSuggestObjectiveModalOpen}
                ></SuggestObjectiveModal>
            )} */}
        {isObjectiveActivityChartsModalOpen === true && (
          <CountObjectivieChartsModal
            isModalOpen={isObjectiveActivityChartsModalOpen}
            closeModal={() => {
              setIsObjectiveActivityChartsModalOpen(false)
            }}
            productSettings={productSettings}
          />
        )}
        {isGanttChartModalOpen === true && (
          <GanttChartModal
            isModalOpen={isGanttChartModalOpen}
            closeModal={() => {
              setIsGanttChartModalOpen(false)
            }}
            isFromSummaryOfObjectives={true}
          />
        )}
        {iStaffingModalOpen === true && staffData !== null && (
          <StaffingTableModal
            isModalOpen={iStaffingModalOpen}
            closeModal={() => {
              setStaffingModalOpen(false)
              setStaffData(null)
            }}
            data={staffData}
          />
        )}
        {isAllocModalOpen === true && (
          <EmployeeAllocationModal
            isModalOpen={isAllocModalOpen}
            closeModal={() => {
              setIsAllocModalOpen(false)
            }}
            employeeAlloc={employeeAlloc}
            teamMembers={areaEmployeesData?.employees?.filter((emp) => emp.isDeactivated === false)}
          />
        )}
        {isInitModalOpen === true && (
          <InitNumberModal
            isModalOpen={isInitModalOpen}
            closeModal={() => {
              setInitModalOpen(false)
            }}
          />
        )}
        {isObjDetailsOpen && (
          <ObjectiveDetailsModal
            isModalOpen={isObjDetailsOpen}
            closeModal={() => {
              setIsObjDetailsOpen(false)
            }}
            objIdIn={parseInt(objectiveSelected.id)}
            showInitiativesId={productSettings}
          />
        )}
        {isRequestUpdateModalOpen && (
          <RequestUpdateModal
            closeModal={() => setIsRequestModalOpen(false)}
            objectiveId={parseInt(objectiveSelected.id)}
          />
        )}
        {isNotificationModalOpen && objectiveSelected && (
          <NotificationsModal
            closeModal={() => {
              setIsNotificationModalOpen(false)
              setObjectiveSelected(null)
            }}
            objectiveId={parseInt(objectiveSelected.id)}
          />
        )}
        {isActivitiesModalOpen && objectiveSelected && activitiesModalMode !== '' && (
          <ObjMapActivitiesModal
            closeModal={() => {
              setIsActivitiesModalOpen(false)
              setActivitiesModalMode('')
              setObjectiveSelected(null)
            }}
            objectiveId={parseInt(objectiveSelected.id)}
            mode={activitiesModalMode}
          />
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '1rem',
            marginTop: '3rem',
            width: '95%',
          }}
        >
          <div
            style={{
              //marginBottom: '1.5rem',
              //padding: '1rem 1rem 1rem 1rem',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-end',
              rowGap: '0.8rem',
              alignItems: 'flex-end',
              width: '100%',
              marginBottom: '1rem',

              //marginLeft: '1rem',

              //Commented out for until we made extra changes to the CEO filter section
              // position: "-webkit-sticky", /* Safari */
              // position: "sticky",
              // top: "0",
              // backgroundColor:"white",
              // zIndex:"10"
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                //padding: '1rem',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                {!(productSettings && hasRole('ic')) && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'flex-start',
                      rowGap: '0.5rem',
                      alignItems: 'flex-start',
                      width: '100%',
                      alignSelf: 'end',
                    }}
                  >
                    <div style={{ fontSize: '1rem', width: '50%' }}>
                      <b>{t('Common:filter.display')}</b>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem' }}>
                      {/* {isTeamLeader() && productSettings && ( */}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-start',
                          columnGap: '1rem',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <div
                          className="option-button"
                          style={{ backgroundColor: showAll === false ? '#4472c4' : '#a6a6a6' }}
                          onClick={() => {
                            setShowAll(false)
                          }}
                        >
                          {t('Common:commonButtons.mine')}
                        </div>
                        {(productSettings || hasRole('area') || hasRole('cxo')) && (
                          <div
                            className="option-button"
                            style={{ backgroundColor: showAll === true ? '#4472c4' : '#a6a6a6' }}
                            onClick={() => {
                              setShowAll(true)
                            }}
                          >
                            {t('Common:commonButtons.all')}
                          </div>
                        )}
                        &nbsp;| &nbsp;
                      </div>
                      {/* )} */}

                      <div
                        class="fitted-button blue"
                        onClick={() => {
                          setIsShowCompleted((prev) => !prev)
                        }}
                      >
                        <span style={{ margin: '0px 0.5rem', height: '24px' }}>
                          {isShowCompleted
                            ? t('Common:commonButtons.showActiveObjectives')
                            : t('Common:commonButtons.showCompletedObjectives')}
                        </span>
                      </div>
                      {productSettings && (
                        <div
                          class="fitted-button blue"
                          style={{
                            alignSelf: 'end',
                            marginBottom: '0.5rem',
                            marginRight: '0.5rem',
                          }}
                          onClick={() => {
                            getObjSpreadsheet()
                          }}
                        >
                          <span class="material-symbols-outlined">ios_share</span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-end',
                    rowGap: '0.5rem',
                    alignItems: 'flex-start',
                    width: '100%',
                    marginBottom: '0.5rem',
                  }}
                >
                  {(((hasRole('area') || hasRole('team') || hasRole('cxo')) && !productSettings) ||
                    hasRole('area') ||
                    isDelegateTl) && (
                    <div style={{ fontSize: '1rem', width: '50%' }}>
                      <b>{t('Common:filter.analysis')}</b>
                    </div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '0.5rem',
                    }}
                  >
                    {(hasRole('team') || hasRole('area')) && showAll === false && (
                      <div
                        class="fitted-button blue"
                        style={{ width: '24px', height: '24px' }}
                        onClick={async () => {
                          setIsGanttChartModalOpen(true)
                        }}
                        title="Gantt Chart View"
                      >
                        <span class="material-symbols-outlined">view_timeline</span>
                      </div>
                    )}
                    {productSettings && (hasRole('area') || isDelegateTl) && (
                      <>
                        <div>
                          <div
                            class="fitted-button blue"
                            onClick={() => {
                              setIsObjectiveActivityChartsModalOpen(true)
                            }}
                          >
                            <span class="material-symbols-outlined">insert_chart</span>
                          </div>
                        </div>
                        <div class="fitted-button blue" onClick={() => getEmployeeAlloc()}>
                          <span class="material-symbols-outlined">group</span>
                        </div>
                        {productSettings && (
                          <div
                            class="fitted-button blue"
                            style={{ width: '24px', height: '24px' }}
                            onClick={handleStaffingTableClick}
                          >
                            <span>PD1</span>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>

                {(isCEO() || isCXO() || isCOS() || isIC()) === false &&
                  productSettings &&
                  (canChangeInitNumber || canAddEditObjs) && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        rowGap: '0.5rem',
                        alignItems: 'flex-start',
                        width: '100%',
                      }}
                    >
                      <div style={{ fontSize: '1rem', width: '50%' }}>
                        <b>{t('Common:filter.addObjective')}</b>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          columnGap: '0.5rem',
                        }}
                      >
                        {/* if it's v-nova, and has rights, then show the button for Tls/ALs */}
                        {productSettings && canAddEditObjs && (
                          <div
                            class="fitted-button blue"
                            style={{ width: '24px', height: '24px' }}
                            onClick={async () => {
                              navigate(`/${routes.demandintake}`, {
                                state: { createNewObj: true, path: currentLocation.pathname },
                              })
                            }}
                          >
                            <span class="material-symbols-outlined">add</span>
                          </div>
                        )}
                        {productSettings && canChangeInitNumber && (
                          <div
                            className="fitted-button blue"
                            style={{ width: '24px', height: '24px' }}
                            onClick={handleInitModalClick}
                          >
                            <span style={{ fontSize: '10px' }}>Init. No.</span>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
              </div>
              {!(productSettings && hasRole('ic')) && <div className="divider"></div>}
              {!isShowCompleted && showDeepDive && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      fontSize: '1rem',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <b style={{ width: '100%' }}>{t('Common:filter.deepDive')}</b>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                      {isDeepDiveSectionHidden && (
                        <span
                          class="material-symbols-outlined"
                          onClick={() => {
                            toggleDeepDiveSection()
                          }}
                          style={{ fontSize: '30px', cursor: 'pointer' }}
                        >
                          arrow_drop_up
                        </span>
                      )}

                      {!isDeepDiveSectionHidden && (
                        <span
                          class="material-symbols-outlined"
                          onClick={() => {
                            toggleDeepDiveSection()
                          }}
                          style={{ fontSize: '30px', cursor: 'pointer' }}
                        >
                          arrow_drop_down
                        </span>
                      )}
                    </div>
                  </div>
                  {isDeepDiveSectionHidden && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        columnGap: '2rem',
                      }}
                    >
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {hideSooAdditionalFilters !== true && (
                          <>
                            <div
                              style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}
                            >
                              <label for="teamLeadersFilter">
                                <b>{t('Common:filter.selectLeader')}:</b>
                              </label>
                              <div
                                data-testid="multi-options-1"
                                style={
                                  {
                                    //width: '16vw'
                                  }
                                }
                              >
                                <Select
                                  placeholder={t('Common:filter.select') + '...'}
                                  value={leadersListFilter}
                                  onChange={handleChangeLeadersList}
                                  maxMenuHeight={100}
                                  getOptionLabel={(option) => option?.name}
                                  getOptionValue={(option) => option?.eId}
                                  options={leadersList}
                                  isDisabled={disableLeadersList}
                                />
                              </div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                rowGap: '0.5rem',
                                margin: '0rem 1rem',
                                alignSelf: 'center',
                              }}
                            >
                              <label for="teamLeadersFilter">
                                <b>{t('Common:filter.or')}</b>
                              </label>
                            </div>
                          </>
                        )}

                        <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                          <label for="teamLeadersFilter">
                            <b>{t('Common:filter.selectObjective')}:</b>
                          </label>
                          <div
                            data-testid="multi-options-1"
                            style={
                              {
                                ///width: '16vw'
                              }
                            }
                          >
                            <Select
                              placeholder={t('Common:filter.select') + '...'}
                              value={objectivesListFilter}
                              onChange={handleChangeObjectiveListFilter}
                              maxMenuHeight={100}
                              getOptionLabel={(option) =>
                                productSettings
                                  ? `${(option?.initiativeId ?? 0).toString().padStart(5, '0')} - ${
                                      option.statement
                                    }`
                                  : `${option.statement}`
                              }
                              getOptionValue={(option) => option?.statement}
                              // options={productSettings ? initiativesData : (showAll? milestonesOverviewData?.objectives : milestonesOverviewData?.objectives.filter(obj => obj.ownerName === localStorage.getItem('tfmnm')))}
                              options={initiativesData}
                              isDisabled={disableObjectivesList}
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'end',
                          columnGap: '1rem',
                        }}
                      >
                        <div
                          class="fitted-button blue"
                          onClick={() => {
                            setShowFilteredObjectives(true)
                            setRefetchObjectivesInfo((prev) => !prev)
                          }}
                        >
                          <span style={{ margin: '0px ', height: '24px' }}>
                            {t('Common:filter.show')}
                          </span>
                        </div>

                        <div
                          class="fitted-button blue"
                          onClick={() => {
                            clearFilters()
                          }}
                        >
                          <span style={{ margin: '0px ', height: '24px' }}>
                            {t('Common:filter.clear')}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {!isShowCompleted && showDeepDive && <div className="divider"></div>}

              {hideSooAdditionalFilters !== true && (
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      columnGap: '3rem',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      width: '100%',
                      marginTop: '0.5rem',
                    }}
                  >
                    <div
                      style={{
                        fontSize: '1rem',
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        justifyContent: 'space-between',
                      }}
                    >
                      <b>{t('Common:filter.filters')}</b>
                      <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                        {isFilterSectionHidden && (
                          <span
                            class="material-symbols-outlined"
                            onClick={() => {
                              toggleFilterSection()
                            }}
                            style={{ fontSize: '30px', cursor: 'pointer' }}
                          >
                            arrow_drop_up
                          </span>
                        )}

                        {!isFilterSectionHidden && (
                          <span
                            class="material-symbols-outlined"
                            onClick={() => {
                              toggleFilterSection()
                            }}
                            style={{ fontSize: '30px', cursor: 'pointer' }}
                          >
                            arrow_drop_down
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {isFilterSectionHidden && (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        columnGap: '3rem',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}
                    >
                      {/* Remove this div layer only when adding more filters*/}
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '0.5rem',
                          width: '100%',
                        }}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row', columnGap: '1rem' }}>
                          {/* Commented out while we decide if we keep it or not */}
                          {/* <div style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}>
                      <label for="teamLeadersFilter">
                        <b>{t('Common:filter.activityAssignee')}:</b>
                      </label>
                      <div data-testid="multi-options-1" style={{ maxWidth: '16vw' }}>
                        <Select
                          value={peopleFilterValue}
                          isMulti
                          onChange={handleChangePeopleFilter}
                          maxMenuHeight={100}
                          getOptionLabel={(option) => option?.name}
                          getOptionValue={(option) => option?.eId}
                          options={areaEmployeesData?.employees}
                        />
                      </div>
                    </div> */}
                          <div
                            style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}
                          >
                            <label for="timeframeFilter">
                              <span>{t('Common:filter.timeframe')}:</span>
                            </label>
                            <div data-testid="multi-options-2" style={{ maxWidth: '10rem' }}>
                              <Select
                                placeholder={t('Common:filter.select') + '...'}
                                value={timeframeFilterValue}
                                isMulti={false}
                                onChange={handleChangeTimeframeFilter}
                                maxMenuHeight={100}
                                styles={{ width: '2rem' }}
                                getOptionLabel={(option) => option?.label}
                                getOptionValue={(option) => option?.value}
                                options={timeframeOptions}
                              />
                            </div>
                          </div>

                          {productSettings && (
                            <div
                              style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}
                            >
                              <label for="teamLeadersFilter">
                                <span>{t('Common:filter.progressFilter')}:</span>
                              </label>
                              <div
                                data-testid="multi-options-1"
                                style={
                                  {
                                    ///maxWidth: '16vw'
                                  }
                                }
                              >
                                <Select
                                  placeholder={t('Common:filter.select') + '...'}
                                  value={statusFilterValue}
                                  isMulti
                                  onChange={handleChangeStatusFilter}
                                  maxMenuHeight={100}
                                  getOptionLabel={(option) => option?.status}
                                  getOptionValue={(option) => option?.id}
                                  options={statuses}
                                />
                              </div>
                            </div>
                          )}

                          {(productSettings || showAll) && (
                            <div
                              style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}
                            >
                              <label>
                                <span>{t('Common:filter.objectiveOwner')}:</span>
                              </label>
                              <div
                                data-testid="multi-options-1"
                                style={
                                  {
                                    //maxWidth: '16vw'
                                  }
                                }
                              >
                                <Select
                                  placeholder={t('Common:filter.select') + '...'}
                                  value={objectiveOwnerValue}
                                  isMulti
                                  onChange={handleChangeObjectiveOwnerFilter}
                                  maxMenuHeight={100}
                                  getOptionLabel={(option) => option?.name}
                                  getOptionValue={(option) => option?.eId}
                                  options={teamLeaders}
                                />
                              </div>
                            </div>
                          )}

                          {/* {productSettings && !isLoading && (
                                <div style={{ display: "flex", flexDirection: "column", rowGap: "0.5rem" }}>
                                    <label for="teamLeadersFilter"><b>{t("Common:filter.initiativeId")}:</b></label>
                                    <div data-testid="multi-options-1"
                                        style={{ maxWidth: "16vw" }}>
                                        <Select
                                            value={initiativeFilterValue}
                                            isMulti
                                            onChange={handleChangeInitiativeFilter}
                                            maxMenuHeight={100}
                                            getOptionLabel={(option) => ((option?.initiativeId).toString().padStart(5, '0'))}
                                            getOptionValue={(option) => option?.initiativeId} options={initiativesData.map(initiativeId => ({ 'initiativeId': initiativeId }))}
                                        />
                                    </div>
                                </div>
                            )} */}
                          {!isLoading && (
                            <div
                              style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}
                            >
                              <label for="teamLeadersFilter">
                                <span>{t('Common:filter.specificObjectives')}:</span>
                              </label>
                              <div data-testid="multi-options-2" style={{ width: '20vw' }}>
                                <Select
                                  placeholder={t('Common:filter.select') + '...'}
                                  value={objectiveFilterValue}
                                  isMulti
                                  onChange={handleChangeObjectiveFilter}
                                  maxMenuHeight={100}
                                  getOptionLabel={(option) =>
                                    productSettings && option.isGeneralObjective === false
                                      ? `${(option?.initiativeId ?? 0)
                                          .toString()
                                          .padStart(5, '0')} - ${option.statement}`
                                      : `${option.statement}`
                                  }
                                  getOptionValue={(option) => option?.statement}
                                  options={
                                    productSettings
                                      ? initiativesData
                                      : showAll
                                        ? initiativesData
                                        : initiativesData.filter(
                                            (obj) =>
                                              obj.ownerName === localStorage.getItem('tfmnm'),
                                          )
                                  }
                                />
                              </div>
                            </div>
                          )}
                          {productSettings && (
                            <div
                              style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}
                            >
                              <label for="staffingStatus">
                                <span>{t('Common:filter.staffingStatus')}:</span>
                              </label>
                              <div style={{ maxWidth: '10rem' }}>
                                <Select
                                  placeholder={t('Common:filter.select') + '...'}
                                  value={staffingFilterValue}
                                  isMulti={false}
                                  onChange={handleChangeStaffingFilter}
                                  maxMenuHeight={100}
                                  styles={{ width: '2rem' }}
                                  getOptionLabel={(option) => option?.label}
                                  getOptionValue={(option) => option?.value}
                                  options={statffingOptions}
                                />
                              </div>
                            </div>
                          )}
                          {!productSettings && (
                            <div
                              style={{ display: 'flex', flexDirection: 'column', rowGap: '0.5rem' }}
                            >
                              {/* <label for="staffingStatus"> */}
                              <label for="tags">
                                <b>{t('Common:filter.tags')}:</b>
                              </label>
                              <div style={{ maxWidth: '10rem' }}>
                                <Select
                                  placeholder={t('Common:filter.select') + '...'}
                                  value={tagFilterValue}
                                  isMulti={true}
                                  onChange={handleChangeTagFilter}
                                  maxMenuHeight={100}
                                  styles={{ width: '2rem' }}
                                  getOptionLabel={(option) => option?.label}
                                  getOptionValue={(option) => option?.value}
                                  options={tagsOptions}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <div
                            class="fitted-button blue"
                            style={{ alignSelf: 'end' }}
                            onClick={() => {
                              applyFilters()
                            }}
                          >
                            <span style={{ margin: '0px 0.5rem', height: '24px' }}>
                              {t('Common:filter.applyFilter')}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
              {hideSooAdditionalFilters !== true && (
                <div className="divider" style={{ marginTop: '0.5rem' }}></div>
              )}
              {!(productSettings && hasRole('ic')) && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    width: isShowCompleted || !showDeepDive ? '100%' : '85%',
                    columnGap: '2rem',
                    color: '#0926D5',
                    textDecoration: 'underline',
                  }}
                >
                  {!shouldCollapse && (
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        uncollapseAll()
                      }}
                    >
                      {t('Common:commonButtons.expandAll')}
                    </span>
                  )}

                  {shouldCollapse && (
                    <span
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        collapseAll()
                      }}
                    >
                      {t('Common:commonButtons.collapseAll')}
                    </span>
                  )}
                  {/* {!hasRole('cxo') && (
                <>
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setIsShowCompleted((prev) => !prev)
                    }}
                  >
                    {isShowCompleted
                      ? t('Common:commonButtons.showActiveObjectives')
                      : t('Common:commonButtons.showCompletedObjectives')}
                  </span>
                </>
              )} */}
                </div>
              )}
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem', width: '100%' }}>
            {!isLoading &&
              milestonesOverviewData.objectives &&
              (productSettings
                ? milestonesOverviewData.objectives
                : showAll
                  ? milestonesOverviewData.objectives
                  : milestonesOverviewData.objectives.filter(
                      (obj) => obj.ownerName === localStorage.getItem('tfmnm'),
                    )
              ).map((obj, objIndex) => (
                <div
                  style={{
                    width: '100%',
                    border: '1px solid rgb(0 0 0 / 20%)',
                    borderRadius: '10px',
                  }}
                >
                  <div style={{ padding: '1rem' }}>
                    {Object.values(objIdsToShowTitle).includes(parseInt(obj.id)) && (
                      // {(objIdsToShowTitle.includes(parseInt(obj.id))) && (
                      <>
                        {showLinkageSummOfObj && (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              columnGap: '1rem',
                              alignItems: 'center',
                              //padding: '0.3rem',
                            }}
                          >
                            <div
                              style={{
                                height: '1.5rem',
                                width: '1.5rem',
                                borderRadius: '50%',
                                display: 'inline-block',
                                color:
                                  obj.level === 1 ? 'purple' : obj.level === 2 ? 'blue' : 'orange',
                                borderColor:
                                  obj.level === 1 ? 'purple' : obj.level === 2 ? 'blue' : 'orange',
                                borderStyle: 'solid',
                                borderWidth: '2px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            >
                              <b>L{obj.level}</b>
                            </div>
                            {obj?.topLevelObjectiveStatement && (
                              <>
                                <div
                                  class="tooltip"
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    columnGap: '1rem',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div class="parents-frame">
                                    {obj?.topLevelObjectiveStatement}

                                    <span class="tooltiptext">
                                      {obj?.topLevelObjectiveStatement}
                                    </span>
                                  </div>
                                  <span
                                    style={{ color: '#C8CFFD' }}
                                    class="material-symbols-outlined"
                                  >
                                    arrow_forward
                                  </span>
                                </div>
                              </>
                            )}
                            {obj?.parentStatement && (
                              <>
                                <div
                                  class="tooltip"
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    columnGap: '1rem',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div class="parents-frame">
                                    {obj?.parentStatement}

                                    <span class="tooltiptext">{obj?.parentStatement}</span>
                                  </div>

                                  <span
                                    style={{ color: '#C8CFFD' }}
                                    class="material-symbols-outlined"
                                  >
                                    arrow_forward
                                  </span>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            columnGap: '1rem',
                            justifyContent: 'space-between',
                            padding: '0.3rem',
                          }}
                        >
                          <div style={{ display: 'flex', flexDirection: 'row', width: '65%' }}>
                            {productSettings &&
                              obj?.initiativeId !== 0 &&
                              !obj?.isGeneralObjective && (
                                <button
                                  className="icon-button group-icon-button"
                                  style={{
                                    width: '2rem',
                                    fontSize: '12px',
                                    marginRight: '0.2rem',
                                  }}
                                  onClick={() => {
                                    setObjectiveSelected(obj)
                                    setIsObjectivePriorityModalOpen(true)
                                  }}
                                  disabled={!canChangeObjPriority}
                                >
                                  <span style={{ width: '5rem' }}>
                                    {obj.priority == 0 ? '--' : obj.priority}
                                  </span>
                                </button>
                              )}

                            <div style={{ display: 'flex' }}>
                              {productSettings &&
                                obj?.initiativeId !== 0 &&
                                !obj?.isGeneralObjective && (
                                  <span style={{ height: 'fit-content' }}>
                                    <div style={{ display: 'flex', margin: '0 0.3rem 0 0.3rem ' }}>
                                      <div>{obj?.initiativeId.toString()?.padStart(5, '0')}</div>
                                      <span style={{ paddingLeft: '0.1rem' }}> - </span>
                                    </div>
                                  </span>
                                )}

                              <span
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  height: 'fit-content',
                                }}
                              >
                                <ObjectiveStatement>{obj.statement}</ObjectiveStatement>
                              </span>
                            </div>
                          </div>

                          <div
                            style={{
                              display: 'flex',
                              width: '50%',
                              justifyContent: 'flex-end',
                              alignItems: 'flex-start',
                              //columnWidth: '0.3rem',
                              columnGap: '0.3rem',
                            }}
                          >
                            <div
                              style={{
                                //marginTop: '0.3rem',
                                display: 'flex',
                                flexDirection: 'row',
                                columnGap: '1rem',
                                justifyContent: 'space-between',
                                //wordBreak: 'break-all',
                                //width:"3rem"
                              }}
                            >
                              {flags.enable_csuite_meeting_hub === 'true' && (
                                <div style={{}}>
                                  {/* <b>{t('summaryOfObjectives.owner')}:</b> {obj.ownerName} */}
                                  <span class=" tooltip">
                                    <NameBox>{obj.ownerName.match(/\b(\w)/g)}</NameBox>
                                    <span class="tooltiptext">{obj.ownerName} </span>
                                  </span>
                                </div>
                              )}
                              {flags.enable_csuite_meeting_hub !== 'true' && (
                                <>
                                  {obj.isOriginal === true ||
                                  (obj.isOriginal === false && obj.originalOwnerId == 0) ? (
                                    <div style={{}}>
                                      {/* <b>{t('summaryOfObjectives.owner')}:</b> {obj.ownerName} */}
                                      <span class="tooltip">
                                        <NameBox>{obj.ownerName.match(/\b(\w)/g)}</NameBox>
                                        <span class="tooltiptext">{obj.ownerName} </span>
                                      </span>
                                    </div>
                                  ) : (
                                    <div style={{}}>
                                      {/* <b>{t('summaryOfObjectives.coOwner')}:</b> {obj.ownerName} */}
                                      <span class="tooltip">
                                        <NameBox>{obj.ownerName.match(/\b(\w)/g)}</NameBox>
                                        <span class="tooltiptext">{obj.ownerName} </span>
                                      </span>
                                    </div>
                                  )}
                                </>
                              )}
                            </div>
                            {!(productSettings && hasRole('ic')) && (
                              <div
                                style={{
                                  alignSelf: 'flex-start',
                                  gap: '0.313rem',
                                  flexDirection: 'row',
                                  display: 'flex',
                                  marginTop: '5px',
                                }}
                              >
                                {obj.isGeneralObjective !== true &&
                                  obj.hasOverdueActivities === true && (
                                    <span class=" tooltip">
                                      <div class="fitted-button yellow-notification">
                                        <span
                                          class="material-symbols-outlined icon-size"
                                          style={{
                                            backgroundColor: '#fff',
                                            pointerEvents: showAll === true ? 'none' : 'auto',
                                          }}
                                          onClick={() => {
                                            setActivitiesModalMode('overdue')
                                            setIsActivitiesModalOpen(true)
                                            setObjectiveSelected(obj)
                                          }}
                                        >
                                          warning
                                        </span>
                                      </div>
                                      <span class="tooltiptext">
                                        {t('ObjectiveMap:legend.overdueActions')}{' '}
                                      </span>
                                    </span>
                                  )}

                                {obj.isGeneralObjective !== true &&
                                  obj.hasAnyProgressLastTwoWeeks === true && (
                                    <span class=" tooltip">
                                      <div class="fitted-button green-notification">
                                        <span
                                          class="material-symbols-outlined icon-size"
                                          style={{
                                            backgroundColor: '#fff',
                                            pointerEvents: showAll === true ? 'none' : 'auto',
                                          }}
                                          onClick={() => {
                                            setObjectiveSelected(obj)
                                            setIsNotificationModalOpen(true)
                                          }}
                                        >
                                          notifications
                                        </span>
                                      </div>
                                      <span class="tooltiptext">
                                        {t('ObjectiveMap:legend.recentActivity')}{' '}
                                      </span>
                                    </span>
                                  )}
                                {obj.isGeneralObjective !== true &&
                                  obj.hasAnyProgressLastTwoWeeks === false && (
                                    <span class=" tooltip">
                                      <div
                                        class="fitted-button red-notification"
                                        style={{ cursor: 'grab' }}
                                      >
                                        <span
                                          class="material-symbols-outlined icon-size"
                                          style={{ backgroundColor: '#fff' }}
                                        >
                                          hourglass_disabled
                                        </span>
                                      </div>
                                      <span class="tooltiptext">
                                        {t('ObjectiveMap:legend.noActivity')}{' '}
                                      </span>
                                    </span>
                                  )}
                              </div>
                            )}
                            {productSettings && (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  fontSize: '13px',
                                  width: '4.5rem',
                                }}
                              >
                                <span>{t('summaryOfObjectives.due')}</span>

                                {!obj?.dueDateStr || obj?.dueDateStr == ''
                                  ? '--'
                                  : formatDueDate(obj?.dueDateStr)}
                              </div>
                            )}
                            {productSettings && obj?.isGeneralObjective === false && (
                              <div>
                                <div style={{ maxWidth: '7vw' }}>
                                  <Select
                                    placeholder={t('Common:filter.select') + '...'}
                                    value={statusOptions.find(
                                      (option) => option.value === obj?.status,
                                    )}
                                    onChange={(selectedOption) =>
                                      handleObjStatusChange(selectedOption, obj)
                                    }
                                    maxMenuHeight={100}
                                    getOptionLabel={(statusOptions) => statusOptions?.label}
                                    getOptionValue={(statusOptions) => statusOptions?.value}
                                    options={statusOptions}
                                    isDisabled={!canChangeObjStatus}
                                    styles={{
                                      option: (provided, state) => ({
                                        ...provided,
                                        minHeight: state.data.label === '' ? '2rem' : 'auto', // Ensure a minimum height for blank options
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                            )}
                            {productSettings && obj?.isGeneralObjective === false && (
                              <>
                                <div
                                  style={{
                                    backgroundColor: `${getKeyByValue(
                                      rags,
                                      buttonClicked[obj.id],
                                    )?.toLowerCase()}`,
                                    width: '2.3rem',
                                    height: '2.3rem',
                                  }}
                                  onClick={() => {
                                    if (!(productSettings && hasRole('ic'))) {
                                      setObjectiveSelected(obj)
                                      setIsRagModalOpenn(true)
                                    }
                                  }}
                                ></div>
                              </>
                            )}
                            <SooDropdownMenu
                              obj={obj}
                              canAddEditObjs={canAddEditObjs}
                              flags={flags}
                              onClickHistory={() => {
                                onClickHistory(obj)
                              }}
                              onClickEdit={() => {
                                onClickEdit(obj)
                              }}
                              onClickComment={() => {
                                onClickComment(obj)
                              }}
                              onClickAssignees={() => {
                                onClickAssignees(obj)
                              }}
                              onClickRequestUpdate={() => {
                                onClickRequestUpdate(obj)
                              }}
                              onClickChangelog={() => {
                                navigate(`/${routes.changeLog}`, { state: { objId: obj.id } })
                              }}
                            />
                            {!(productSettings && hasRole('ic')) && (
                              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                {isHidden[objIndex]?.overview && (
                                  <span
                                    class="material-symbols-outlined"
                                    onClick={() => {
                                      toggleContent(objIndex)
                                    }}
                                    style={{ fontSize: '30px', cursor: 'pointer' }}
                                  >
                                    arrow_drop_down
                                  </span>
                                )}

                                {!isHidden[objIndex]?.overview && (
                                  <span
                                    class="material-symbols-outlined"
                                    onClick={() => {
                                      toggleContent(objIndex)
                                    }}
                                    style={{ fontSize: '30px', cursor: 'pointer' }}
                                  >
                                    arrow_drop_up
                                  </span>
                                )}
                              </div>
                            )}
                          </div>

                          {/* {flags.enable_csuite_meeting_hub !== 'true' && obj.isOriginal === true && (
                          <div
                            class="fitted-button blue"
                            onClick={() => {
                              setObjectiveSelected(obj)
                              setIsCoOwnedObjectiveAllocModalOpen(true)
                            }}
                          >
                            <span class="material-symbols-outlined">groups</span>
                          </div>
                        )}
                        {flags.enable_csuite_meeting_hub === 'true' &&
                          obj?.originalId &&
                          obj?.originalId !== 0 && (
                            <div
                              class="fitted-button blue"
                              onClick={() => {
                                setObjectiveSelected(obj)
                                setIsCoOwnedObjectiveAllocModalOpen(true)
                              }}
                            >
                              <span class="material-symbols-outlined">groups</span>
                            </div>
                          )} */}
                          {/* <div
                        style={{
                          marginLeft: 'auto',
                          display: 'flex',
                          flexDirection: 'row',
                          columnGap: '0.5rem',
                        }}
                      >
                        <div
                          class="fitted-button blue"
                          onClick={() => {
                            setObjectiveSelected(obj)
                            setIsObjDetailsOpen(true)
                          }}
                        >
                          <span class="material-symbols-outlined">history</span>
                        </div>
                        <div
                          className={'fitted-button blue ' + canBeDisabledNoCommentsObj(obj)}
                          onClick={() => {
                            setObjectiveSelected(obj)
                            setDisplayAllObjectives(true)
                            openCommentsModal()
                          }}
                        >
                          <span class="material-symbols-outlined">comment</span>
                        </div>

                        {!obj.isGeneralObjective && canAddEditObjs && obj?.level === 3 && (
                          <div
                            class="fitted-button blue"
                            onClick={() => {
                              setObjectiveSelected(obj)
                              // setIsAddObjectiveModalOpen(true)
                              navigate(`/${routes.demandintake}`, {
                                state: {
                                  editObj: true,
                                  objective: obj,
                                  path: currentLocation.pathname,
                                  ownerId: obj.ownerId,
                                },
                              })
                            }}
                          >
                            <span class="material-symbols-outlined">edit</span>
                          </div>
                          // <div
                          //   className="edit-button"
                          //   onClick={() => {
                          //     setObjectiveSelected(obj)
                          //     // setIsAddObjectiveModalOpen(true)
                          //     navigate(`/${routes.demandintake}`, {
                          //       state: {
                          //         editObj: true,
                          //         objective: obj,
                          //         path: currentLocation.pathname,
                          //         ownerId: obj.ownerId,
                          //       },
                          //     })
                          //   }}
                          // >
                          //   <img className="edit-btn" src={edit} style={{ bottom: '0' }} />
                          // </div>
                        )}
                        
                        
                      </div> */}
                        </div>
                        {/* <div
                      class="divider"
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundColor: 'rgb(127,127,127)',
                        height: '3px',
                      }}
                    /> */}
                      </>
                    )}

                    {/* <div style={{ paddingBottom: '0.3rem', marginLeft: '0.3rem' }}>
                  {obj?.objIcMappings?.map((person, personIndex) => (
                    <>
                      {employeesWithColors.map((emp, empIndex) => (
                        <>
                          {emp.eId === person.eId && (
                            <span
                              style={{
                                padding: '0px 6px',
                                borderRadius: '25px',
                                border: '2px solid rgb(68, 84, 106)',
                                backgroundColor: emp.color,
                                color: 'white',
                                marginLeft: '0.2rem',
                              }}
                            >
                              {person.eName.match(/\b(\w)/g)}
                            </span>
                          )}
                        </>
                      ))}
                    </>
                  ))}
                </div> */}

                    {!isHidden[objIndex]?.overview && (
                      <>
                        <div className="milestones-container" style={{ fontSize: '14px' }}>
                          <React.Fragment key={`obj-${objIndex}`}>
                            <div className="divider" style={{ marginBottom: '0.4rem' }}></div>
                            <div>
                              {productSettings && (
                                <div
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '100%',
                                  }}
                                >
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <b>{t('summaryOfObjectives.comments')}</b>
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      gap: '3rem',
                                    }}
                                  >
                                    <>
                                      {!isHidden[objIndex]?.comment && (
                                        <b>{t('summaryOfObjectives.commenter')}</b>
                                      )}
                                      {!isHidden[objIndex]?.comment && (
                                        <b>{t('summaryOfObjectives.date')}</b>
                                      )}
                                      <b>
                                        <span
                                          className="material-symbols-outlined"
                                          onClick={() => toggleComment(objIndex)}
                                          style={{ fontSize: '20px', cursor: 'pointer' }}
                                        >
                                          {isHidden[objIndex]?.comment
                                            ? 'arrow_drop_down'
                                            : 'arrow_drop_up'}
                                        </span>
                                      </b>
                                    </>
                                  </div>
                                </div>
                              )}
                              {productSettings && !isHidden[objIndex]?.comment && (
                                <div>
                                  {commentData?.comments?.filter(
                                    (com) => parseInt(com.objId) === parseInt(obj.id),
                                  )?.length > 0 ? (
                                    commentData?.comments
                                      ?.filter((com) => parseInt(com.objId) === parseInt(obj.id))
                                      .map((com) => {
                                        return (
                                          <div
                                            key={com?.commentId}
                                            style={{
                                              paddingLeft: '0.8rem',
                                              width: '100%',
                                              display: 'flex',
                                              alignItems: 'center',
                                              marginBottom: '0.5rem',
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                flex: 1,
                                                paddingRight: '1rem',
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: '1.5rem',
                                                  marginRight: '0.5rem',
                                                }}
                                              >
                                                &bull;
                                              </span>
                                              <span
                                                style={{
                                                  wordBreak: 'break-word',
                                                  flex: 1,
                                                }}
                                              >
                                                {linkifyString(com?.text)}
                                              </span>
                                            </div>
                                            <div
                                              style={{ textAlign: 'right', paddingRight: '2.5rem' }}
                                            >
                                              {com.eName}
                                            </div>
                                            <div
                                              style={{ textAlign: 'right', paddingRight: '4.3rem' }}
                                            >
                                              {dateFilter(com.timestamp)}
                                            </div>
                                          </div>
                                        )
                                      })
                                  ) : (
                                    <div style={{ color: '#555', paddingLeft: '1rem' }}>--</div>
                                  )}
                                  {obj?.bizRationale && (
                                    <div
                                      key={objIndex}
                                      style={{
                                        paddingLeft: '0.8rem',
                                        width: '100%',
                                        display: 'flex',
                                        columnGap: '1rem',
                                      }}
                                    >
                                      <span style={{ fontSize: '1.5rem' }}>&bull;</span>
                                      <span
                                        style={{
                                          wordBreak: 'break-word',
                                          display: 'flex',
                                          alignItems: 'center',
                                        }}
                                      >
                                        {linkifyString('Business Rationale: ' + obj?.bizRationale)}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              )}
                              {productSettings && (
                                <div
                                  className="divider"
                                  style={{ marginBottom: '0.4rem', marginTop: '0.4rem' }}
                                ></div>
                              )}
                              {getCompanyConfigSettings('showKpiUpdatePageInUW') === true &&
                                !productSettings && (
                                  <div className="kpi-status-table-summary-obj">
                                    <div className="kpi-status-table-header-summary-obj">
                                      {t('Common:kpi.kpi')}
                                    </div>
                                    {!isHidden[objIndex]?.kpi ? (
                                      <>
                                        <div className="kpi-status-table-header-summary-obj">
                                          {t('Common:kpi.target')}
                                        </div>
                                        {/* <div className="kpi-status-table-header-summary-obj">{t('Common:kpi.unit')}</div> */}
                                        <div className="kpi-status-table-header-summary-obj">
                                          {t('Common:kpi.reachBy')}
                                        </div>
                                        <div className="kpi-status-table-header-summary-obj">
                                          {' '}
                                          {t('Common:kpi.frequency')}
                                        </div>
                                        <div
                                          className="kpi-status-table-header-summary-obj"
                                          style={{ paddingLeft: '1rem' }}
                                        >
                                          {t('Common:kpi.latestStatus')}
                                        </div>
                                        <div className="kpi-status-table-header-summary-obj">
                                          {t('Common:kpi.ownerName')}
                                        </div>
                                        <div className="kpi-status-table-header-summary-obj">
                                          {t('Common:kpi.latestUpdate')}
                                        </div>
                                        <div className="kpi-status-table-header-summary-obj">
                                          {t('Common:kpi.updatedOn')}
                                        </div>
                                        {/* <div className="kpi-status-table-header-summary-obj">{t('Common:kpi.updatedBy')}</div> */}
                                        <div className="kpi-status-table-header-summary-obj">
                                          {t('Common:kpi.topics')}
                                        </div>
                                        <div className="kpi-status-table-header-summary-obj">
                                          {t('Common:kpi.addViewUpdates')}
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="kpi-status-table-header-summary-obj"></div>
                                        <div className="kpi-status-table-header-summary-obj"></div>
                                        <div className="kpi-status-table-header-summary-obj"></div>
                                        <div className="kpi-status-table-header-summary-obj"></div>
                                        {/* <div className="kpi-status-table-header-summary-obj"></div> */}
                                        <div className="kpi-status-table-header-summary-obj"></div>
                                        <div className="kpi-status-table-header-summary-obj"></div>
                                        <div className="kpi-status-table-header-summary-obj"></div>
                                        <div className="kpi-status-table-header-summary-obj"></div>
                                        <div className="kpi-status-table-header-summary-obj"></div>
                                      </>
                                    )}
                                    <div className="kpi-status-table-header-summary-obj">
                                      <span
                                        class="material-symbols-outlined"
                                        onClick={() => toggleKpi(objIndex)}
                                        style={{ fontSize: '20px', cursor: 'pointer' }}
                                      >
                                        {isHidden[objIndex]?.kpi
                                          ? 'arrow_drop_down'
                                          : 'arrow_drop_up'}
                                      </span>
                                    </div>

                                    {!isHidden[objIndex]?.kpi &&
                                      (obj.kpis?.filter((kpi) => !kpi?.isCompleted)?.length > 0 ? (
                                        obj.kpis
                                          ?.filter((kpi) => {
                                            return !kpi?.isCompleted
                                          })
                                          ?.sort((a, b) => {
                                            if (a?.isCompleted && !b?.isCompleted) {
                                              return 1
                                            }
                                            if (!a?.isCompleted && b?.isCompleted) {
                                              return -1
                                            }
                                            return 0
                                          })
                                          ?.map((kpi, kpiIndex) => {
                                            return (
                                              <>
                                                <React.Fragment key={`objective-${kpi.kpiId}`}>
                                                  <>
                                                    <div
                                                      className="kpi-status-table-cell-summary-obj"
                                                      style={{
                                                        textAlign: 'left',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-between',
                                                      }}
                                                    >
                                                      <span>{kpi.name}</span>
                                                    </div>
                                                    <div
                                                      className="kpi-status-table-cell-summary-obj"
                                                      style={{
                                                        textAlign: 'left',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-between',
                                                      }}
                                                    >
                                                      <span>
                                                        {kpi.kpiType === 3
                                                          ? '---'
                                                          : kpi.target.toLocaleString()}
                                                      </span>
                                                    </div>
                                                    {/* <div
                                              className="kpi-status-table-cell"
                                              style={{
                                                textAlign: 'left',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                              }}
                                            >
                                              <span>{kpi.unit === '' ? '---' : kpi?.unit}</span>
                                            </div> */}
                                                    <div
                                                      className="kpi-status-table-cell-summary-obj"
                                                      style={{
                                                        textAlign: 'left',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-between',
                                                      }}
                                                    >
                                                      <span>{kpi.targetDate}</span>
                                                    </div>
                                                    <div
                                                      className="kpi-status-table-cell-summary-obj"
                                                      style={{
                                                        textAlign: 'left',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-between',
                                                      }}
                                                    >
                                                      <span>
                                                        {convertFrequencyEnumToText(kpi.frequency)}
                                                      </span>
                                                    </div>
                                                    <div
                                                      className="kpi-status-table-cell-summary-obj"
                                                      style={{}}
                                                    >
                                                      {
                                                        <div
                                                          style={{
                                                            backgroundColor:
                                                              kpi.ragStatus === -1
                                                                ? 'lightgray'
                                                                : kpi.ragStatus === 0
                                                                  ? 'green'
                                                                  : kpi.ragStatus === 1
                                                                    ? 'gold'
                                                                    : 'red',
                                                            width: '2rem',
                                                            height: '2rem',
                                                            marginLeft: '1rem',
                                                          }}
                                                        ></div>
                                                      }
                                                    </div>
                                                    <div
                                                      className="kpi-status-table-cell-summary-obj"
                                                      style={{
                                                        textAlign: 'left',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-between',
                                                      }}
                                                    >
                                                      <span>{shortenName(kpi.ownerName)}</span>
                                                    </div>
                                                    <div
                                                      className="kpi-status-table-cell-summary-obj"
                                                      style={{
                                                        textAlign: 'left',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-between',
                                                      }}
                                                    >
                                                      <span>
                                                        {kpi.ragStatus === -1
                                                          ? '---'
                                                          : kpi.kpiType === 3
                                                            ? convertMilestoneEnumToText(
                                                                kpi.lastUpdateAmount,
                                                              )
                                                            : kpi.lastUpdateAmount.toLocaleString()}
                                                      </span>
                                                    </div>
                                                    <div
                                                      className="kpi-status-table-cell-summary-obj"
                                                      style={{
                                                        textAlign: 'left',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-between',
                                                        color:
                                                          kpi.isKpiUpdateOverdue &&
                                                          kpi.ragStatus !== -1
                                                            ? 'red'
                                                            : 'black',
                                                      }}
                                                    >
                                                      <span>
                                                        {kpi.ragStatus === -1
                                                          ? '---'
                                                          : dateFilter(kpi.lastUpdateTs)}
                                                      </span>
                                                    </div>
                                                    {/* <div
                                              className="kpi-status-table-cell"
                                              style={{
                                                textAlign: 'left',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                              }}
                                            >
                                              <span>
                                                {kpi.ragStatus === -1
                                                  ? '---'
                                                  : shortenName(kpi.lastUpdateCreatedBy)}
                                              </span>
                                            </div> */}

                                                    <div
                                                      className="kpi-status-table-cell-summary-obj"
                                                      style={{
                                                        textAlign: 'left',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-between',
                                                        color:
                                                          kpi.isKpiUpdateOverdue &&
                                                          kpi.ragStatus !== -1
                                                            ? 'red'
                                                            : 'black',
                                                      }}
                                                    >
                                                      <span>
                                                        {/* {kpi.ragStatus === -1 ? '---' : dateFilter(kpi.lastUpdateTs)} */}
                                                        {console.log('kpi', kpi)}
                                                        {kpi.lastUpdateUmtId === 0 && (
                                                          <span>{'---'}</span>
                                                        )}
                                                        {kpi.lastUpdateUmtId > 0 &&
                                                          (kpi.ragStatus === 1 ||
                                                            kpi.ragStatus === 2) && (
                                                            <span
                                                              style={{
                                                                color: 'blue',
                                                                textDecoration: 'underline',
                                                                cursor: 'pointer',
                                                              }}
                                                              onClick={() => {
                                                                setTopicId(kpi.lastUpdateUmtId)

                                                                if (kpi.ragStatus === 1) {
                                                                  setTopicType(2)
                                                                } else if (kpi.ragStatus === 2) {
                                                                  setTopicType(1)
                                                                }

                                                                setIsTopicModalOpen(true)
                                                              }}
                                                            >
                                                              {kpi.ragStatus === 1
                                                                ? t('Common:kpi.seeGuidance')
                                                                : kpi.ragStatus === 2
                                                                  ? t('Common:kpi.seeBlocker')
                                                                  : '---'}
                                                            </span>
                                                          )}
                                                      </span>
                                                    </div>

                                                    <div
                                                      className="kpi-status-table-cell-summary-obj"
                                                      style={{
                                                        display: 'flex',

                                                        gap: '0.5rem',
                                                        textAlign: 'left',
                                                        padding: '0',
                                                      }}
                                                    >
                                                      <div
                                                        className="fitted-button blue tooltip"
                                                        style={{ backgroundColor: 'white' }}
                                                        onClick={() => {
                                                          setObjId(obj.id)
                                                          setSelectedKpiUpdate(kpi)
                                                          setIsOpenKPIUpdatesModal(true)
                                                        }}
                                                      >
                                                        <span className="material-symbols-outlined">
                                                          visibility
                                                        </span>
                                                      </div>
                                                      {kpi.isCompleted === false && (
                                                        <div
                                                          className="fitted-button blue tooltip"
                                                          style={{ backgroundColor: 'white' }}
                                                          onClick={() => {
                                                            setObjId(obj.id)
                                                            setSelectedKpiUpdate(kpi)
                                                            setIsOpenUpdateModal(true)
                                                          }}
                                                        >
                                                          <span className="material-symbols-outlined">
                                                            add
                                                          </span>
                                                        </div>
                                                      )}
                                                    </div>

                                                    <div
                                                      className="kpi-status-table-cell-summary-obj"
                                                      style={{
                                                        textAlign: 'left',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'center',
                                                        alignContent: 'flex-start',
                                                      }}
                                                    >
                                                      <KpiDropdownMenu
                                                        onClickEdit={() => {
                                                          setObjId(obj.id)
                                                          setSelectedKpi(kpi)
                                                          setAddEditModalMode('edit')
                                                          setIsOpenAddEditKPIModal(true)
                                                        }}
                                                        onClickDelete={() => {
                                                          deleteKpiFn(kpi, obj.id)
                                                        }}
                                                        onClickUpdateComplete={() => {
                                                          setObjId(obj.id)
                                                          updateComplete(kpi, obj.id)
                                                        }}
                                                        canDelete={kpi.ragStatus === -1}
                                                        canEdit={kpi?.isCompleted === false}
                                                        isCompleted={kpi?.isCompleted}
                                                      />
                                                    </div>
                                                  </>
                                                </React.Fragment>
                                              </>
                                            )
                                          })
                                      ) : (
                                        <div style={{ color: '#555', paddingLeft: '1rem' }}>--</div>
                                      ))}
                                  </div>
                                )}
                              {getCompanyConfigSettings('showKpiUpdatePageInUW') === true &&
                                !productSettings && (
                                  <div
                                    className="divider"
                                    style={{ marginBottom: '0.4rem', marginTop: '0.4rem' }}
                                  ></div>
                                )}
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                }}
                              >
                                <b>{t('summaryOfObjectives.meetingTopicsTitle')}</b>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  {/* {!isHidden[objIndex]?.bgu && (
                                  <div
                                    className="fitted-button blue"
                                    onClick={() => {
                                      setObjectiveSelected(obj)
                                      setIsActivityModalOpen(true)
                                    }}
                                    style={{ marginRight: '0.5rem' }}
                                  >
                                    <span className="material-symbols-outlined">add</span>
                                  </div>
                                )} */}
                                  <span
                                    className="material-symbols-outlined"
                                    onClick={() => toggleBGU(objIndex)}
                                    style={{ fontSize: '20px', cursor: 'pointer' }}
                                  >
                                    {isHidden[objIndex]?.bgu ? 'arrow_drop_down' : 'arrow_drop_up'}
                                  </span>
                                </div>
                              </div>
                              {!isHidden[objIndex]?.bgu && (
                                <div>
                                  {topicsData?.objectives?.filter(
                                    (tps) => parseInt(obj.id) === parseInt(tps.objId),
                                  )?.length > 0 ? (
                                    getAllTopicsData(obj.id).map((topic, ind, topicsArray) => {
                                      const isLastTopic = ind === topicsArray.length - 1
                                      return (
                                        <div style={{ paddingLeft: '1rem' }}>
                                          <>
                                            <>
                                              <div
                                                className="meeting-topic-blocker line_break_div"
                                                style={{
                                                  marginTop: '0.5rem',
                                                  padding: '0rem 0rem 0rem 1rem',
                                                  display: 'flex',
                                                  alignItems: 'center',
                                                  overflow: 'hidden',
                                                }}
                                              >
                                                <span
                                                  style={{ flexShrink: 0, cursor: 'pointer' }}
                                                  onClick={() =>
                                                    handleClickTopic(topic.umtId, topic.topicType)
                                                  }
                                                >
                                                  {getActionBox(topic.topicType)}
                                                </span>
                                                <span
                                                  style={{
                                                    marginLeft: '0.5rem',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    marginRight: '0.2rem',
                                                  }}
                                                >
                                                  {topic.actionItem}
                                                </span>

                                                {topic.kpiAssociatedId && (
                                                  <>
                                                    -
                                                    <span
                                                      style={{
                                                        color: '#0926D5',
                                                        textDecoration: 'underline',
                                                      }}
                                                    >
                                                      <span
                                                        style={{
                                                          cursor: 'pointer',
                                                          fontSize: '16px',
                                                        }}
                                                        onClick={async () => {
                                                          try {
                                                            let response = await getHttpRequest(
                                                              `/get_kpi_details/${topic.kpiAssociatedId}`,
                                                            )
                                                            console.log('response', response)
                                                            setObjId(obj.id)
                                                            setSelectedKpiUpdate(response)
                                                            setIsOpenKPIUpdatesModal(true)
                                                          } catch (error) {
                                                            Toast.fire({
                                                              icon: 'error',
                                                              title: t(
                                                                'Common:modalMessages.somethingWentWrongTryAgainLater',
                                                              ),
                                                            })
                                                            console.log(
                                                              'Error fetching KPI details',
                                                              error,
                                                            )
                                                          }
                                                        }}
                                                      >
                                                        &nbsp;
                                                        {topic?.kpiAssociatedName ??
                                                          t('meetingTopics.fromKpi')}
                                                      </span>
                                                    </span>
                                                  </>
                                                )}
                                              </div>

                                              {!isLastTopic && (
                                                <div
                                                  className="divider"
                                                  style={{ marginTop: '0.4rem' }}
                                                ></div>
                                              )}
                                            </>
                                          </>

                                          {/* {decisions?.length > 0 && (
                                          <>
                                            {blockers && blockers.length > 0 ? <div></div> : <></>}
                                            {decisions.map((decision, decisionIndex) => (
                                              <>
                                                <div
                                                  className="meeting-topic-decision line_break_div"
                                                  style={{
                                                    marginTop: '0.5rem',
                                                    padding: '0rem 0rem 0rem 1rem',
                                                    display: 'flex',  
                                                    alignItems: 'center', 
                                                    overflow: 'hidden', 
                                                  }}
                                                >
                                                  <span style={{ flexShrink: 0, cursor: 'pointer', }} onClick={() => handleClickTopic(decision.umtId, decision.topicType)}>{getActionBox(decision.topicType)}</span>
                                                  <span
                                                    style={{
                                                      marginLeft: '0.5rem', 
                                                      whiteSpace: 'nowrap',
                                                      overflow: 'hidden',  
                                                      textOverflow: 'ellipsis', 
                                                      flex: 1,
                                                    }}
                                                  >
                                                    {decision.actionItem}
                                                  </span>
                                                </div>
                                                {!(isLastTopic && decisionIndex === decisions.length - 1) && (
                                                  <div className="divider" style={{ marginTop: '0.4rem' }}></div>
                                                )}
                                              </>
                                            ))}
                                          </>
                                        )}
                                        {updates?.length > 0 && (
                                          <>
                                            {(blockers && blockers.length > 0) ||
                                              (decisions && decisions.length > 0) ? (
                                              <div></div>
                                            ) : (
                                              <></>
                                            )}
                                            {updates.map((update, updateIndex) => (
                                              <>
                                                <div
                                                  className="meeting-topic-update line_break_div"
                                                  style={{
                                                    marginTop: '0.5rem',
                                                    padding: '0rem 0rem 0rem 1rem',
                                                    display: 'flex',
                                                    alignItems: 'center', 
                                                    overflow: 'hidden', 
                                                  }}
                                                >
                                                  <span style={{ flexShrink: 0, cursor: 'pointer'}} onClick={() => handleClickTopic(update.umtId, update.topicType)}>{getActionBox(update.topicType)}</span>
                                                  <span
                                                    style={{
                                                      marginLeft: '0.5rem',
                                                      whiteSpace: 'nowrap', 
                                                      overflow: 'hidden',   
                                                      textOverflow: 'ellipsis', 
                                                      flex: 1, 
                                                    }}
                                                  >
                                                    {update.actionItem}
                                                  </span>
                                                </div>
                                                {!(isLastTopic && updateIndex === updates.length - 1) && (
                                                  <div className="divider" style={{ marginTop: '0.4rem' }}></div>
                                                )}
                                              </>
                                            ))}
                                          </>
                                        )} */}
                                        </div>
                                      )
                                    })
                                  ) : (
                                    <div style={{ color: '#555', paddingLeft: '1rem' }}>
                                      {/* {t('summaryOfObjectives.noMeetingTopicsMessage')} */}
                                      --
                                    </div>
                                  )}
                                </div>
                              )}
                              <div className="divider" style={{ marginTop: '0.4rem' }}></div>
                              {milestonesOverviewData.milestones.filter(
                                (mls) =>
                                  parseInt(obj.id) === parseInt(mls.objectiveId) &&
                                  mls.completedTs === 0,
                              )?.length > 0 &&
                                productSettings && (
                                  <div
                                    style={{
                                      display: 'grid',
                                      gridTemplateColumns: ' 7fr 1fr 0.7fr 0.7fr',
                                      gridAutoRows: '0.6fr',
                                      alignItems: 'center',
                                      columnGap: '1rem',
                                    }}
                                  >
                                    <h3 style={{ margin: '0.3rem 0 0.3rem 0' }}>
                                      <MilestonesColumnTitle class="scorecard-subtitle">
                                        {t('summaryOfObjectives.activities')}
                                      </MilestonesColumnTitle>
                                    </h3>
                                    <h3 style={{ margin: '0.3rem 0 0.3rem 0' }}>
                                      <MilestonesColumnTitle class="scorecard-subtitle">
                                        {!isHidden[objIndex]?.action &&
                                          t('summaryOfObjectives.progress')}
                                      </MilestonesColumnTitle>
                                    </h3>

                                    <h3 style={{ margin: '0.3rem 0 0.3rem 0' }}>
                                      <MilestonesColumnTitle class="scorecard-subtitle">
                                        {!isHidden[objIndex]?.action &&
                                          t('summaryOfObjectives.dueDate')}
                                      </MilestonesColumnTitle>
                                    </h3>
                                    <h3 style={{ margin: '0.3rem 0 0.3rem 0' }}>
                                      <div
                                        style={{
                                          display: 'flex',
                                          flexDirection: 'row',
                                          justifyContent: 'flex-end',
                                        }}
                                      >
                                        {!isHidden[objIndex]?.action && (
                                          <div
                                            class="fitted-button blue"
                                            onClick={() => {
                                              setObjectiveSelected(obj)
                                              setIsActivityModalOpen(true)
                                            }}
                                            style={{ marginRight: '0.5rem' }}
                                          >
                                            <span class="material-symbols-outlined">add</span>
                                          </div>
                                        )}
                                        <span
                                          className="material-symbols-outlined"
                                          onClick={() => toggleAction(objIndex)}
                                          style={{ fontSize: '20px', cursor: 'pointer' }}
                                        >
                                          {isHidden[objIndex]?.action
                                            ? 'arrow_drop_down'
                                            : 'arrow_drop_up'}
                                        </span>
                                      </div>
                                    </h3>

                                    {/* TODO(Ceci): Add messages for empty milestones and empty blockers */}
                                    {!isHidden[objIndex]?.action &&
                                      milestonesOverviewData.milestones &&
                                      milestonesOverviewData.milestones
                                        .filter(
                                          (mls) =>
                                            parseInt(obj.id) === parseInt(mls.objectiveId) &&
                                            mls.completedTs === 0,
                                        )
                                        ?.map((mls) => {
                                          return (
                                            <>
                                              <div className="line_break_div">
                                                {removeMentionMarkupFromText(mls.description)}
                                                <br />
                                                {mls.assigneeList.length > 0 && (
                                                  <>
                                                    {mls?.assigneeList?.map(
                                                      (person, personIndex) => (
                                                        <>
                                                          {employeesWithColors.map(
                                                            (emp, empIndex) => (
                                                              <>
                                                                {emp.eId === person.eId && (
                                                                  <span
                                                                    style={{
                                                                      padding: '0px 6px',
                                                                      borderRadius: '25px',
                                                                      border:
                                                                        '2px solid rgb(68, 84, 106);',
                                                                      backgroundColor: emp.color,
                                                                      color: 'white',
                                                                    }}
                                                                  >
                                                                    {person.name.match(/\b(\w)/g)}
                                                                  </span>
                                                                )}
                                                              </>
                                                            ),
                                                          )}
                                                        </>
                                                      ),
                                                    )}
                                                  </>
                                                )}
                                              </div>

                                              <div>{mls.progressTitle}</div>

                                              <div>
                                                {mls.dueDate === 0 ? null : dateFilter(mls.dueDate)}
                                              </div>

                                              <div
                                                style={{
                                                  display: 'flex',
                                                  flexDirection: 'row',
                                                  justifyContent: 'flex-end',
                                                  columnGap: '0.5rem',
                                                  paddingBottom: '0.3rem',
                                                }}
                                              >
                                                <div
                                                  className={
                                                    'fitted-button blue ' +
                                                    canBeDisabledNoCommentsMls(mls)
                                                  }
                                                  onClick={() => {
                                                    setObjectiveSelected(obj)
                                                    setTaskIdSelected(mls.milestoneId)
                                                    openCommentsModal()
                                                  }}
                                                >
                                                  <span class="material-symbols-outlined">
                                                    comment
                                                  </span>
                                                </div>

                                                <div
                                                  class="fitted-button blue"
                                                  onClick={() => {
                                                    setObjectiveSelected(obj)
                                                    setTaskIdSelected(mls.milestoneId)
                                                    setIsActivityModalOpen(true)
                                                  }}
                                                >
                                                  <span class="material-symbols-outlined">
                                                    edit
                                                  </span>
                                                </div>
                                              </div>
                                            </>
                                          )
                                        })}
                                  </div>
                                )}
                              {milestonesOverviewData.milestones.filter(
                                (mls) =>
                                  parseInt(obj.id) === parseInt(mls.objectiveId) &&
                                  mls.completedTs === 0,
                              )?.length == 0 &&
                                productSettings && (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      justifyContent: 'space-between',
                                      marginTop: '1rem',
                                    }}
                                  >
                                    <div style={{ color: '#555' }}>
                                      {t('summaryOfObjectives.noActivitiesMessage')}
                                    </div>
                                    {!obj.isGeneralObjective && (
                                      <div
                                        class="fitted-button blue"
                                        onClick={() => {
                                          setObjectiveSelected(obj)
                                          setIsActivityModalOpen(true)
                                        }}
                                      >
                                        <span class="material-symbols-outlined">add</span>
                                      </div>
                                    )}
                                  </div>
                                )}
                            </div>
                          </React.Fragment>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ))}
          </div>
          {isLoading && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                height: '10rem',
              }}
            >
              <InPageLoader inComponent={true} />
            </div>
          )}
        </div>
      </ApplicationLayout>
    </>
  )
}

export default SummaryOfObjectives
